import { usePreferences, useUpdatePreference } from 'hooks/usePreferences';
import { atom } from 'jotai';
import { useAtomCallback } from 'jotai/utils';
import { useCallback } from 'react';
import { PreferenceName, PreferenceVideoProvider } from 'types/preference';
import { SettingsPageType } from 'components/Settings/types';
import { ModalType } from 'types/modal';
import { useUpdateModal, useUpdateSettingPageModal } from 'hooks/useModal';
import { useUserQuery } from 'graphql/queries/user.graphql';

export function useOpenSettingsPage() {
  const { openModal } = useUpdateModal();
  const setSettingsPage = useUpdateSettingPageModal();
  const openSettingsTab = useCallback(
    (tab: SettingsPageType) => {
      setSettingsPage(tab);
      openModal(ModalType.Settings);
    },
    [openModal, setSettingsPage]
  );

  return {
    openSettingsTab,
  };
}

export const isOpenAroundSectionAtom = atom(false);

export const useAround = () => {
  const preferences = usePreferences();
  const updatePreference = useUpdatePreference();
  const preferenceName = PreferenceName.AroundLink;
  const aroundLink = preferences?.aroundLink;
  const videoProvider = preferences.videoProvider;

  const onSubmit = useAtomCallback(
    useCallback(
      (get, set, value: string) => {
        const isAroundSectionOpen = get(isOpenAroundSectionAtom);

        if (aroundLink !== value) {
          updatePreference(preferenceName, value).then(() => {
            if (!value && videoProvider === PreferenceVideoProvider.Around) {
              // if the provider was Around but no link is provided, set default to None
              updatePreference(
                PreferenceName.VideoProvider,
                PreferenceVideoProvider.None
              );
            } else if (
              value &&
              videoProvider !== PreferenceVideoProvider.Around &&
              isAroundSectionOpen // selected Around intentionally
            ) {
              // if Around option is selected when no link was present, and link is provided - set default to Around
              updatePreference(
                PreferenceName.VideoProvider,
                PreferenceVideoProvider.Around
              );
            }
          });
        }

        // the section will remain open if there's a link added, no need to force open it
        // it will close if no link
        set(isOpenAroundSectionAtom, false);
      },
      [aroundLink, preferenceName, updatePreference, videoProvider]
    )
  );

  return { aroundLink, videoProvider, onSubmit };
};

export const useGetMeetingOptions = () => {
  const preferences = usePreferences();

  const [userQueryRes] = useUserQuery({
    requestPolicy: 'cache-only',
  });

  const updatePreference = useUpdatePreference();
  const preferenceName = PreferenceName.VideoProvider;
  const preferenceValue = preferences?.videoProvider;
  const aroundLink = preferences?.aroundLink || '';
  const zoomConnected = Boolean(
    userQueryRes.data?.new_user[0].zoomRefreshToken
  );
  const { openSettingsTab } = useOpenSettingsPage();

  const onSelectAround = useAtomCallback(
    useCallback(
      (_, set) => {
        if (aroundLink) {
          updatePreference(preferenceName, PreferenceVideoProvider.Around);
        } else {
          set(isOpenAroundSectionAtom, true);
        }
      },
      [aroundLink, preferenceName, updatePreference]
    )
  );

  const getRadioListItems = useCallback(() => {
    const items = [
      {
        title: 'Around',
        description: aroundLink ? aroundLink : 'Set your Around link',
        selected: preferenceValue === PreferenceVideoProvider.Around,
        onSelect: onSelectAround,
      },
      {
        title: 'Google Meet',
        description: 'Meet links will automatically be attached to events',
        selected: preferenceValue === PreferenceVideoProvider.Meet,
        onSelect: () =>
          updatePreference(preferenceName, PreferenceVideoProvider.Meet),
      },
      {
        title: 'Zoom',
        description: zoomConnected
          ? 'Your Zoom account is connected. Zoom links will be generated from your Zoom account'
          : 'Connect your Zoom account',
        selected: preferenceValue === PreferenceVideoProvider.Zoom,
        onSelect: () => {
          if (zoomConnected) {
            updatePreference(preferenceName, PreferenceVideoProvider.Zoom);
          } else {
            openSettingsTab(SettingsPageType.Connections);
          }
        },
      },
      {
        title: 'None',
        description: `Don't add video links to events by default`,
        selected:
          !preferenceValue || preferenceValue === PreferenceVideoProvider.None,
        onSelect: () =>
          updatePreference(preferenceName, PreferenceVideoProvider.None),
      },
    ];
    return items;
  }, [
    aroundLink,
    onSelectAround,
    openSettingsTab,
    preferenceName,
    preferenceValue,
    updatePreference,
    zoomConnected,
  ]);

  return getRadioListItems;
};
