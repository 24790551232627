import IconLocationMarker from 'components/Icons/IconLocationMarker';
import {
  ListItem,
  NavItemLocationPicker,
  NavItemType,
} from 'components/QuickMenu/types';
import { useUpdateGridEvent } from 'hooks/events/useUpdateGridEvent';
import { useUpdateModal } from 'hooks/useModal';
import React, { useCallback } from 'react';
import { IGridEvent } from 'types/events';
import { EVENT_PLACEHOLDER_LOCATION } from 'utils/events';
import { getVideoProviderEnum } from 'utils/video';
import useSetNavItems from '../useSetNavItems';

export const useChangeLocationAction = (fullEvents: IGridEvent[]): ListItem => {
  const eventId = fullEvents[0]?.id;
  const { saveGridEvent, updateGridEvent } = useUpdateGridEvent();
  const { closeModal } = useUpdateModal();
  const { addNavItem } = useSetNavItems();

  const setEventValue = useCallback(
    <KeyName extends keyof IGridEvent>(keyName: KeyName) =>
      (value: IGridEvent[KeyName] | undefined) => {
        updateGridEvent({ id: eventId, [keyName]: value });
      },
    [eventId, updateGridEvent]
  );

  const changeLocationAction = () => {
    const navItem: NavItemLocationPicker = {
      type: NavItemType.locationPicker,
      title: `Change location`,
      inputPlaceholder: EVENT_PLACEHOLDER_LOCATION,
      hidden: true,
      onChooseLocation: (location) => {
        const provider = getVideoProviderEnum(location);

        if (provider && eventId) {
          // we need to add videoConferences information about provider
          // to enable generated links to correctly fill in the location field when ready
          setEventValue('videoConferences')([
            {
              link: location || '',
              provider,
            },
          ]);
        }

        if (eventId) {
          saveGridEvent({
            id: eventId,
            location,
          });
        }

        closeModal();
      },
    };

    addNavItem({
      type: navItem.type,
      fullObject: navItem,
    });
  };

  return {
    id: 'changeLocation',
    title: `Change location`,
    type: 'eventsSelectionOption',
    Icon: () => <IconLocationMarker className="h-5 w-5 p-0.5 text-gray-400" />,
    actionNameOverride: 'Change',
    actionOverride: changeLocationAction,
  };
};
