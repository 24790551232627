import { HookProps, ListGroup, NavItemType } from 'components/QuickMenu/types';
import { isSearching } from 'components/QuickMenu/utils';
import { useUserEmail } from 'contexts/auth';
import useContactSearch from 'hooks/useContactSearch';
import { useAtomValue } from 'jotai/utils';
import Avatar from 'joy/Avatar';
import React, { useRef } from 'react';
import { getContactDescription, getContactTitle } from './navItemsHelpers';
import { quickMenuNavItemsAtom } from './useSetNavItems';

const SEARCH_CONTACTS_RESULT_LIMIT = 8;

export function useContactsListGroup({
  enabled,
  searchTerm,
}: HookProps): ListGroup {
  const navItems = useAtomValue(quickMenuNavItemsAtom);
  const lastNavItem = navItems[navItems.length - 1];
  const shouldRender =
    enabled &&
    (isSearching(searchTerm) ||
      lastNavItem?.type === NavItemType.contactSearch);

  const userEmail = useUserEmail();
  let finalSearchTerm = searchTerm || '';

  // Constrain the results to the user's domain if the onlySameDomain flag is enabled.
  if (
    lastNavItem?.type === NavItemType.contactSearch &&
    lastNavItem.onlySameDomain
  ) {
    finalSearchTerm += ' @' + userEmail?.split('@')[1];
  }

  // If contactSearch is on, and there is no searchTerm, then search for a space to get
  // some results populated.
  const { results } = useContactSearch({
    term: finalSearchTerm,
    limit: shouldRender ? SEARCH_CONTACTS_RESULT_LIMIT : 0,
    exclude: userEmail ? [userEmail] : undefined,
  });

  const listGroupRef = useRef<ListGroup>({
    title: 'People',
    items: [],
  });
  listGroupRef.current.items.length = 0;

  if (!shouldRender) return listGroupRef.current;

  results.forEach((contact) => {
    listGroupRef.current.items.push({
      id: contact.id,
      title: getContactTitle(contact),
      email: contact.emailAddress,
      description: getContactDescription(contact),
      type: 'contact',
      actionNameOverride:
        lastNavItem?.type === NavItemType.contactSearch &&
        lastNavItem.getActionNameOverride
          ? lastNavItem.getActionNameOverride(contact)
          : undefined,
      Icon: () => (
        <Avatar
          src={contact.avatar}
          name={contact.displayName || contact.emailAddress}
        />
      ),
      actionOverride:
        lastNavItem?.type === NavItemType.contactSearch
          ? () => {
              lastNavItem.callback(contact);
            }
          : undefined,
    });
  });

  return listGroupRef.current;
}
