import { NewEventRsvpEnum } from '@graphql-types@';
import { atom } from 'jotai';
import { useAtomValue } from 'jotai/utils';
import { EventDiff, IGridEvent } from 'types/events';

export type EventConfirmationCallback = (
  prevMessages: EventConfirmation[]
) => EventConfirmation[];

export interface EventConfirmation extends EventConfirmationPayload {
  id: string;
  isRecurring: boolean;
  onResolve: (results: EventConfirmationResolver) => unknown;
}

export interface EventConfirmationResolver {
  notifyGuests: boolean;
  applyToFutureEvents: boolean;
  cancelled: boolean;
  notifyMessage?: string;
}

type EventConfirmationProps = EventConfirmationPayload & {
  setEventConfirmations(callback: EventConfirmationCallback): void;
};
interface EventConfirmationPayload {
  event: Pick<
    IGridEvent,
    'title' | 'recurringEventId' | 'recurrenceRules' | 'attendees' | 'id'
  >;
  type: 'create' | 'update' | 'delete';
  userEmail?: string;
  diff?: EventDiff;
}

export const eventConfirmationsAtom = atom<EventConfirmation[]>([]);
export const EVENT_CONFIRMATION_KEY_WHITELIST: (keyof IGridEvent)[] = [
  'title',
  'description',
  'location',
  'recurrenceRules',
  'rsvp',
  'startAt',
  'endAt',
  'attendees',
  'colorFamily',
  'visibility',
];

export function useEventConfirmations(): EventConfirmation[] {
  return useAtomValue(eventConfirmationsAtom);
}

export function confirmEventChanges({
  setEventConfirmations,
  ...payload
}: EventConfirmationProps): Promise<EventConfirmationResolver> {
  const isRecurring =
    !!payload.event.recurringEventId ||
    (payload.event?.recurrenceRules || []).length > 0;

  if (blockWithoutConfirmation(payload, isRecurring)) {
    return Promise.resolve({
      cancelled: false,
      notifyGuests: false,
      applyToFutureEvents: false,
    });
  }

  if (blockWithoutConfirmationAndApply(payload)) {
    // Apply to all events if RSVP'ed "yes", or single if "no"
    const applyToFutureEvents = applyRSVPToFutureEvents(payload);
    return Promise.resolve({
      cancelled: false,
      notifyGuests: false,
      applyToFutureEvents: applyToFutureEvents,
    });
  }

  return new Promise((resolve) => {
    setEventConfirmations((prev) => {
      return [
        ...prev.filter(({ id }) => id !== payload.event.id),
        {
          id: payload.event.id,
          ...payload,
          isRecurring,
          onResolve: (results) => {
            // Continue to creation, update, or deletion of the event
            resolve(results);

            // Clear the confirmation from the stack
            setEventConfirmations((prev) =>
              prev.filter(({ id }) => id !== payload.event.id)
            );
          },
        },
      ];
    });
  });
}

function blockWithoutConfirmation(
  { event, diff, type, userEmail }: EventConfirmationPayload,
  isRecurring: boolean
): boolean {
  const changedFields = getFieldKeys(diff);
  const hasNoChanges = changedFields.length === 0;

  // If there are no changes on create or update, don't show confirmation
  if (hasNoChanges && type !== 'delete') return true;

  // Handle RSVP in next check for applying to future events
  if (isSingleMatch(changedFields, 'rsvp')) return false;

  // If the event was checked, don't show confirmation
  if (isSingleMatch(changedFields, 'doneAt')) return true;

  const hasGuests =
    event.attendees.filter(
      (attendee) => !attendee.organizer && attendee.email !== userEmail
    ).length > 0;
  const hasGuestChanges = diff && 'attendees' in diff && diff.attendees;
  const requiresGuestNotification = hasGuests || hasGuestChanges;

  // If there are guests or changes to guests, continue to confirmation
  if (requiresGuestNotification) return false;

  // If there are any changes to recurring events, continue to confirmation
  if (isRecurring) return false;

  // For anything else, don't show confirmation
  return true;
}

function blockWithoutConfirmationAndApply({
  type,
  diff,
}: EventConfirmationPayload): boolean {
  // If we're deleting, continue to confirmation
  if (type === 'delete') return false;

  const changedFields = getFieldKeys(diff);

  // If the event was checked, dont show confirmation
  if (isSingleMatch(changedFields, 'colorFamily')) return true;

  // If the event rsvp changes, don't show confirmation
  if (isSingleMatch(changedFields, 'rsvp')) return true;

  // If the event rsvp changes, dont show confirmation
  if (isSingleMatch(changedFields, 'visibility')) return true;

  // For other conditions that don't need to apply to all future events, show confirmation
  return false;
}

function isSingleMatch(
  fields: (keyof IGridEvent)[],
  fieldName: keyof IGridEvent
): boolean {
  return fields.length === 1 && fields[0] === fieldName;
}

function applyRSVPToFutureEvents({ diff }: EventConfirmationPayload) {
  const changedFields = getFieldKeys(diff);

  // Default apply to all future events
  if (!isSingleMatch(changedFields, 'rsvp') || !diff) return true;

  // Apply to all if "yes", or to single one if "no"
  return getNewRSVPValue(diff) === NewEventRsvpEnum.Yes;
}

function getFieldKeys(diff: EventDiff | undefined): (keyof IGridEvent)[] {
  return Object.keys(diff || {}) as (keyof IGridEvent)[];
}

function getNewRSVPValue(diff: EventDiff): NewEventRsvpEnum | undefined {
  const rsvp = diff?.rsvp;
  if (!rsvp || typeof rsvp.new !== 'string') return undefined;

  return rsvp.new as NewEventRsvpEnum;
}
