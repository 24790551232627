import { Setter } from 'jotai';
import {
  eventIdsPoolAtom,
  optimisticEventsFamily,
  serverEventsAtomFamily,
} from '../eventAtoms';

export function removeEventIdFromPool(set: Setter, eventId: string): void {
  set(eventIdsPoolAtom, (prevIds) => {
    prevIds.delete(eventId);
    return new Set(prevIds);
  });
}

export function removeEventFromPool(set: Setter, eventId: string): void {
  removeEventIdFromPool(set, eventId);
  optimisticEventsFamily.remove(eventId);
  serverEventsAtomFamily.remove(eventId);
}
