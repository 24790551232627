import { HookProps, ListGroup } from 'components/QuickMenu/types';
import { useUserEmail } from 'contexts/auth';
import { useUpdateContactByPkMutation } from 'graphql/mutations/UpdateContact.graphql';
import useContactSearch from 'hooks/useContactSearch';
import useFavorites from 'hooks/useFavorites';
import { useUpdateModal } from 'hooks/useModal';
import { useSetVisibleCalendars } from 'hooks/useVisibleCalendars';
import Avatar from 'joy/Avatar';
import React, { useMemo, useRef } from 'react';
import { getContactDescription, getContactTitle } from './navItemsHelpers';

const SEARCH_CONTACTS_RESULT_LIMIT = 8;

export function useCalendarsListGroup({
  enabled,
  searchTerm,
}: HookProps): ListGroup {
  const favorites = useFavorites();
  const { addCalendar } = useSetVisibleCalendars();
  const userEmail = useUserEmail();
  const exclude = useMemo(() => {
    const excludedEmails = favorites.map((c) => c.emailAddress);
    if (userEmail) excludedEmails.push(userEmail);
    return excludedEmails;
  }, [favorites, userEmail]);

  const { results } = useContactSearch({
    term: (searchTerm || '') + ' @' + userEmail?.split('@')[1],
    limit: SEARCH_CONTACTS_RESULT_LIMIT,
    exclude,
  });

  const { closeModal } = useUpdateModal();
  const [, updateContact] = useUpdateContactByPkMutation();

  const listGroupRef = useRef<ListGroup>({
    title: 'Add to sidebar',
    items: [],
  });

  listGroupRef.current.items.length = 0;
  if (!enabled) return listGroupRef.current;

  results.forEach((contact) => {
    listGroupRef.current.items.push({
      id: contact.id,
      title: getContactTitle(contact),
      email: contact.emailAddress,
      description: getContactDescription(contact),
      type: 'calendar',
      actionOverride: async () => {
        try {
          await updateContact({
            id: contact.id,
            _set: { favoriteAt: new Date().toISOString() },
          });
          // Not awaiting this one since it may take some time to load...
          addCalendar(contact.emailAddress);

          closeModal();
        } catch (error) {
          console.error('Failed to favorite the contact');
        }
      },
      Icon: () => (
        <Avatar
          src={contact.avatar}
          name={contact.displayName || contact.emailAddress}
        />
      ),
    });
  });

  return listGroupRef.current;
}
