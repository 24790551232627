/* eslint-disable react-hooks/exhaustive-deps */
import { ColorFamily, NewEventRsvpEnum } from '@graphql-types@';
import { dragEventAtom } from 'components/Todos/todosAtoms';
import {
  confirmEventChanges,
  EventConfirmationCallback,
  eventConfirmationsAtom,
  EVENT_CONFIRMATION_KEY_WHITELIST,
} from 'hooks/useEventConfirmations';
import { urqlClientWithoutSubscriptions } from 'graphql/client';
import {
  CreateEventDocument,
  CreateEventMutation,
  CreateEventMutationVariables,
} from 'graphql/mutations/CreateEvent.graphql';
import {
  DeleteEventDocument,
  DeleteEventMutation,
  DeleteEventMutationVariables,
} from 'graphql/mutations/DeleteEvent.graphql';
import {
  UpdateEventDocument,
  UpdateEventMutation,
  UpdateEventMutationVariables,
} from 'graphql/mutations/UpdateEvent.graphql';
import { preferencesAtom } from 'hooks/preferences/preferencesAtoms';
import { eventsSelectionAtom } from 'hooks/useEventsSelection';
import { userAtom } from 'hooks/user/userAtoms';
import { Getter, Setter } from 'jotai/core/typeUtils';
import { useAtomCallback } from 'jotai/utils';
import { EventName } from 'types/analytics';
import { IGridEvent, ServerEvent } from 'types/events';
import { trackEvent } from 'utils/analytics';
import {
  gridEventsFamily,
  interactionOnlyEventsFamily,
  optimisticEventsFamily,
  optimisticMutationTimestamps,
  eventIdsPoolAtom,
  serverEventsAtomFamily,
  visibleEventsIdsAtom,
} from './eventAtoms';
import {
  formatCreateEventPayload,
  formatServerEvent,
  formatUpdateEventPayload,
  generateEventUUID,
  getEventDiff,
  getEventDiffProps,
  getVisibilityAsEnum,
} from './helpers/eventsHelpers';
import { DateTime } from 'luxon';
import { timezoneAtom } from 'hooks/useTimeZone';
import { modalAtom } from 'hooks/useModal';
import { ModalType } from 'types/modal';
import { isDraftEvent } from 'components/Grid/utils';
import { userEmailAtom } from 'contexts/auth';
import {
  optimisticDeleteRecurringInstances,
  optimisticUpdateRecurringInstances,
} from './helpers/recurringEventsHelpers';

const permittedGuestAttributes: (keyof IGridEvent)[] = [
  'colorFamily',
  'rsvp',
  'doneAt',
  'doneBy',
];

export function useUpdateGridEvent() {
  return {
    updateGridEventForInteractionOnly: useAtomCallback(
      updateGridEventForInteractionOnlyAtomCallback
    ),
    applyInteractionOnlyChanges: useAtomCallback(
      applyInteractionOnlyChangesAtomCallback
    ),
    updateGridEvent: useAtomCallback(updateGridEventAtomCallback),
    deleteGridEvent: useAtomCallback(_deleteGridEvent),
    saveGridEvent: useAtomCallback(_saveGridEvent),
    revertGridEvent: useAtomCallback(_revertGridEvent),
    createGridEventFromProps: useAtomCallback(_rawCreateGridEvent),
    duplicateGridEvent: useAtomCallback(_duplicateGridEvent),
    createDraftEvent: useAtomCallback(createDraftEventAtomCallback),
    deleteDraftEvent: useAtomCallback(_deleteDraftEvent),
  };
}

interface UpdateGridEventProps extends Partial<IGridEvent> {
  id: string;
}

/**
 * Small optimisation on top of updateGridEventAtomCallback to prevent unnecessary re-renders
 */
const FIVE_MINUTES_MS = 300_000;
export function updateGridEventForInteractionOnlyAtomCallback(
  get: Getter,
  set: Setter,
  props: Pick<UpdateGridEventProps, 'id' | 'startAt' | 'endAt'>
) {
  const event = get(interactionOnlyEventsFamily(props.id));
  if (
    !props.startAt ||
    !props.endAt ||
    (Math.abs(
      (event?.startAt.toMillis() || 0) - (props.startAt?.toMillis() || 0)
    ) < FIVE_MINUTES_MS &&
      Math.abs(
        (event?.endAt.toMillis() || 0) - (props.endAt?.toMillis() || 0)
      ) < FIVE_MINUTES_MS)
  ) {
    return;
  }
  set(interactionOnlyEventsFamily(props.id), {
    startAt: props.startAt,
    endAt: props.endAt,
  });
}
/**
 * Small optimisation on top of updateGridEventAtomCallback to prevent unnecessary re-renders
 */
export function applyInteractionOnlyChangesAtomCallback(
  get: Getter,
  set: Setter,
  props: { id: string }
) {
  const interactionOnlyUpdates = get(interactionOnlyEventsFamily(props.id));
  set(interactionOnlyEventsFamily(props.id), null);
  updateGridEventAtomCallback(get, set, {
    ...props,
    ...interactionOnlyUpdates,
  });
}

export function updateGridEventAtomCallback(
  get: Getter,
  set: Setter,
  props: UpdateGridEventProps
): void {
  const interactionOnlyUpdates = get(interactionOnlyEventsFamily(props.id));
  if (interactionOnlyUpdates) {
    set(interactionOnlyEventsFamily(props.id), null);
  }
  const mutationTimestamp = Date.now();
  set(optimisticMutationTimestamps(props.id), mutationTimestamp);
  set(optimisticEventsFamily(props.id), (prevValues) => ({
    ...prevValues,
    ...interactionOnlyUpdates,
    ...props,
  }));
}

interface SaveGridEventProps
  extends Partial<IGridEvent>,
    EventConfirmationProps {
  id: string;
  forceSave?: boolean;
}

async function _saveGridEvent(
  get: Getter,
  set: Setter,
  props: SaveGridEventProps
) {
  applyInteractionOnlyChangesAtomCallback(get, set, { id: props.id });
  set(optimisticEventsFamily(props.id), (prevValues) => ({
    ...prevValues,
    ...props,
  }));
  const displayedGridEvent = get(gridEventsFamily(props.id));
  if (isDraftEvent(displayedGridEvent)) {
    return _createGridEvent(get, set, props);
  }

  const isEventPopoverOpen = get(modalAtom) === ModalType.Event;
  if (isEventPopoverOpen && !props.forceSave)
    return updateGridEventAtomCallback(get, set, props);

  const timezone = get(timezoneAtom);
  const mutationTimestamp = Date.now();
  const optimisticEvent = get(optimisticEventsFamily(props.id));

  if (optimisticEvent?.status === 'cancelled') {
    // Don't save deleted events
    return;
  }

  set(optimisticEventsFamily(props.id), (prevValues) => ({
    ...prevValues,
    ...props,
  }));
  set(optimisticMutationTimestamps(props.id), mutationTimestamp);
  const serverEvent = get(serverEventsAtomFamily(props.id));
  const dragOverScheduleEvent = get(dragEventAtom);
  const userEmail = get(userEmailAtom);

  let updatedAttributes = getEventDiff({
    originalEvent: serverEvent,
    updatedEvent: displayedGridEvent,
    whitelistedKeys: EVENT_CONFIRMATION_KEY_WHITELIST,
  });

  if (!displayedGridEvent?.canEdit) {
    updatedAttributes = updatedAttributes.filter((x) =>
      permittedGuestAttributes.includes(x)
    );
  }

  if (
    !serverEvent ||
    !optimisticEvent ||
    !displayedGridEvent ||
    dragOverScheduleEvent != null
  ) {
    return;
  }

  const setEventConfirmations = (updateValue: EventConfirmationCallback) =>
    set(eventConfirmationsAtom, updateValue);

  const diff = getEventDiffProps(
    updatedAttributes,
    serverEvent,
    displayedGridEvent,
    userEmail
  );

  const { notifyGuests, applyToFutureEvents, cancelled, notifyMessage } =
    await confirmEventChanges({
      diff,
      event: serverEvent,
      type: 'update',
      userEmail,
      setEventConfirmations,
    });

  if (cancelled) {
    set(optimisticEventsFamily(props.id), null);
    set(optimisticMutationTimestamps(props.id), null);
    return;
  }
  // Allow for overlap algorithm to update
  set(serverEventsAtomFamily(props.id), displayedGridEvent);

  if (applyToFutureEvents) {
    optimisticUpdateRecurringInstances(get, set, displayedGridEvent);
  }

  const normalizedPayload = formatUpdateEventPayload(displayedGridEvent);
  const result = await updateEventMutation({
    ...normalizedPayload,
    notifyGuests,
    notifyMessage,
    recurringEventId: applyToFutureEvents
      ? displayedGridEvent.recurringEventId
      : null,
  });

  if (updatedAttributes.includes('recurrenceRules')) {
    trackEvent(EventName.SetEventRecurring);
  }

  // Only apply if this mutation is from the latest optimistic update.
  const updatedEvent = result?.event as ServerEvent;
  const mostRecentOptimisticTimestamp = get(
    optimisticMutationTimestamps(props.id)
  );
  const hasBeenDeleted =
    get(optimisticEventsFamily(props.id))?.status === 'cancelled';
  if (
    updatedEvent &&
    !hasBeenDeleted &&
    (!mostRecentOptimisticTimestamp ||
      mutationTimestamp >= mostRecentOptimisticTimestamp)
  ) {
    set(
      serverEventsAtomFamily(props.id),
      formatServerEvent(updatedEvent, updatedEvent.calendarId, timezone)
    );
    set(optimisticEventsFamily(props.id), null);
    set(optimisticMutationTimestamps(props.id), null);
  }
}

interface DeleteGridEventProps extends EventConfirmationProps {
  eventId: string;
  calendarId?: string;
}

async function _deleteGridEvent(
  get: Getter,
  set: Setter,
  props: DeleteGridEventProps
) {
  set(optimisticEventsFamily(props.eventId), (prevValues) => ({
    ...prevValues,
    status: 'cancelled',
  }));
  const serverEvent = get(serverEventsAtomFamily(props.eventId));
  const eventToDelete = get(gridEventsFamily(props.eventId));
  if (!serverEvent || !eventToDelete) {
    return;
  }
  const setEventConfirmations = (updateValue: EventConfirmationCallback) =>
    set(eventConfirmationsAtom, updateValue);
  const { notifyGuests, applyToFutureEvents, cancelled, notifyMessage } =
    await confirmEventChanges({
      event: eventToDelete,
      type: 'delete',
      setEventConfirmations,
    });
  if (cancelled) {
    set(optimisticEventsFamily(props.eventId), null);
    return;
  }
  // Optimistically hide related event ids
  if (eventToDelete.recurringEventId && applyToFutureEvents) {
    optimisticDeleteRecurringInstances(get, set, eventToDelete);
  }
  await deleteEventMutation({
    ...props,
    notifyGuests,
    notifyMessage,
    applyToFutureEvents,
  });
}

function _revertGridEvent(get: Getter, set: Setter, props: { id: string }) {
  const serverEvent = get(serverEventsAtomFamily(props.id));
  if (serverEvent) {
    set(optimisticEventsFamily(props.id), null);
  }
}

async function _createGridEvent(
  get: Getter,
  set: Setter,
  props: Partial<IGridEvent>
) {
  if (!props.id) {
    console.error('_createGridEvent: missing id');
    return;
  }
  const gridEvent = get(gridEventsFamily(props.id));
  if (!gridEvent?.title) {
    return;
  }

  const eventToCreate = { ...gridEvent, ...props };
  return _rawCreateGridEvent(get, set, eventToCreate);
}

async function _rawCreateGridEvent(
  get: Getter,
  set: Setter,
  gridEvent: Partial<IGridEvent>
) {
  const timezone = get(timezoneAtom);

  const calendarId = get(userAtom)?.email;
  if (!calendarId) {
    console.error(
      '_createGridEvent: no email found on user to set the calendarId'
    );
    return;
  }

  if (!gridEvent?.title) {
    return;
  }

  const userEmail = get(userEmailAtom);

  const eventToCreate: IGridEvent = {
    ...gridEvent,
    isDraft: false,
    status: 'confirmed',
  } as IGridEvent;

  const updatedAttributes = getEventDiff({
    originalEvent: draftEvent,
    updatedEvent: eventToCreate,
    whitelistedKeys: ['attendees'],
  });

  const diff = getEventDiffProps(
    updatedAttributes,
    draftEvent,
    eventToCreate,
    userEmail
  );

  const setEventConfirmations = (updateValue: EventConfirmationCallback) =>
    set(eventConfirmationsAtom, updateValue);
  const { notifyGuests, cancelled } = await confirmEventChanges({
    event: eventToCreate,
    type: 'create',
    diff,
    userEmail,
    setEventConfirmations,
  });

  if (cancelled) {
    set(optimisticEventsFamily(eventToCreate.id), (draft) => ({
      ...draft,
      isDraft: true,
      status: 'cancelled',
    }));
    return;
  }

  set(eventsSelectionAtom, []);
  set(serverEventsAtomFamily(eventToCreate.id), eventToCreate);
  set(optimisticEventsFamily(eventToCreate.id), eventToCreate);
  set(eventIdsPoolAtom, (prevIds) => new Set([...prevIds, eventToCreate.id]));

  const normalizedPayload = formatCreateEventPayload(eventToCreate);

  const result = await createEventMutation({
    ...normalizedPayload,
    notifyGuests,
  });

  const serverEvent = result?.event as ServerEvent;
  if (serverEvent) {
    set(
      serverEventsAtomFamily(eventToCreate.id),
      formatServerEvent(serverEvent, calendarId, timezone)
    );
  } else {
    console.warn("_createGridEvent: event wasn't updated in the state");
  }

  return result;
}

/**
 * Duplicating an event is the same as creating an event
 * as long as you give the id, the corresponding event will
 * be retrieved to make the copy
 */
function _duplicateGridEvent(
  get: Getter,
  set: Setter,
  props: Partial<IGridEvent>
) {
  trackEvent(EventName.DuplicatedEvent);
  return _createGridEvent(get, set, {
    ...props,
    doneAt: null,
  });
}

export function createDraftEventAtomCallback(
  get: Getter,
  set: Setter,
  props: Partial<IGridEvent>
): void {
  const user = get(userAtom);
  const calendarId = user?.email;

  if (!calendarId) {
    console.error(
      '_createDraftEvent: no email found on user to set the calendarId'
    );
    return;
  }

  // Set default title for events to "name1 x my name" format.
  if (
    !props.title &&
    user &&
    props.attendees?.length === 1 &&
    props.attendees?.[0].displayName
  ) {
    const userName = user.displayName?.split(' ')[0] || user.email;
    const nameOrEmpty = props.attendees[0].displayName.split(' ')[0] || '';
    props.title = `${userName} x ${nameOrEmpty}`;
  }

  const newDraftEvent = {
    ...draftEvent,
    isDraft: true,
    id: props.id || generateEventUUID(),
  };

  const userPreferences = get(preferencesAtom);
  set(optimisticEventsFamily(newDraftEvent.id), {
    ...newDraftEvent,
    calendarId,
    createdAt: new Date(),
    visibility: getVisibilityAsEnum(userPreferences?.todoPrivacy),
    ...props,
  });
  set(eventIdsPoolAtom, (prevIds) => new Set([...prevIds, newDraftEvent.id]));
}

function _deleteDraftEvent(get: Getter, set: Setter) {
  const calendarId = get(userAtom)?.email;
  if (!calendarId) {
    console.error(
      '_createDraftEvent: no email found on user to set the calendarId'
    );
    return;
  }
  set(eventsSelectionAtom, []);
  set(eventIdsPoolAtom, (prevIds) => {
    prevIds.forEach((id) => {
      const event = get(optimisticEventsFamily(id));
      if (!event) {
        return;
      } else if (isDraftEvent(event) && !event.title) {
        prevIds.delete(id);
        set(optimisticEventsFamily(id), null);
      } else if (isDraftEvent(event)) {
        set(optimisticEventsFamily(id), (draft) => ({
          ...draft,
          status: 'cancelled',
        }));
      }
    });
    return new Set(prevIds);
  });
}

interface EventConfirmationProps {
  notifyGuests?: boolean;
  applyToFutureEvents?: boolean;
  notifyMessage?: string;
}

type INewGridEvent = Omit<
  IGridEvent,
  | 'calendarId'
  | 'createdAt'
  | 'startAt'
  | 'endAt'
  | 'dayIndex'
  | 'schedule'
  | 'visibility'
>;

export const draftEvent: INewGridEvent = {
  id: 'no_id',
  title: '',
  isAllDay: false,
  status: 'confirmed',
  videoConferences: [],
  description: '',
  isOwnEvent: true,
  isSelfAsAttendee: true,
  allOtherGuestsDeclined: false,
  attendees: [],
  location: '',
  colorFamily: undefined, // Because it should inherit color of it's calendar
  canEdit: true,
  belongsToUserCalendar: true,
  prevEndAt: DateTime.now(),
  prevStartAt: DateTime.now(),
  rsvp: NewEventRsvpEnum.Yes,
  isDraft: true,
};

async function updateEventMutation(payload: UpdateEventMutationVariables) {
  trackEvent(EventName.UpdatedEvent);

  return urqlClientWithoutSubscriptions
    .mutation<UpdateEventMutation>(UpdateEventDocument, payload, {
      requestPolicy: 'cache-and-network',
    })
    .toPromise()
    .then((response) => {
      return response.data?.updateEvent;
    });
}

async function deleteEventMutation(payload: DeleteEventMutationVariables) {
  trackEvent(EventName.DeletedEvent);

  return urqlClientWithoutSubscriptions
    .mutation<DeleteEventMutation>(DeleteEventDocument, payload, {
      requestPolicy: 'cache-and-network',
    })
    .toPromise();
}

async function createEventMutation(payload: CreateEventMutationVariables) {
  trackEvent(EventName.CreatedEvent);

  return urqlClientWithoutSubscriptions
    .mutation<CreateEventMutation>(CreateEventDocument, payload, {
      requestPolicy: 'cache-and-network',
    })
    .toPromise()
    .then((response) => {
      return response.data?.createEvent;
    });
}
