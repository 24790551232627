import { useCallback } from 'react';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { atom } from 'jotai';

export const eagerCalendarsAtom = atom<string[]>([]);

export const useEagerCalendars = () => useAtomValue(eagerCalendarsAtom);

export default function useCalendarsEagerly() {
  const setCalendars = useUpdateAtom(eagerCalendarsAtom);

  const eagerlyLoadCalendar = useCallback(
    (calendarIds: string | string[]) => {
      setCalendars((prevCalendarIds) => {
        if (typeof calendarIds === 'string') {
          if (prevCalendarIds.find((id) => id === calendarIds)) {
            return prevCalendarIds;
          }
          return [...prevCalendarIds, calendarIds];
        }

        return calendarIds.concat(...calendarIds);
      });
    },
    [setCalendars]
  );

  return {
    eagerlyLoadCalendar,
  };
}
