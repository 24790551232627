import { ColorFamily } from '@graphql-types@';
import classNames from 'classnames';
import IconCheckboxMark from 'components/Icons/IconCheckboxMark';
import { PreventAutocompleteAttributes } from 'components/PreventAutocompleteAttributes';
import Hidden from 'joy/Hidden';
import { isReactText } from 'joy/utils';
import React, { PointerEventHandler, ReactElement, useMemo } from 'react';
import { EventColor } from 'types/color';

type CheckboxColor = 'blue' | 'red';
interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    'value' | 'color'
  > {
  disabled?: boolean;
  value?: boolean;
  variant?: 'event' | 'selectedevent' | 'base';
  colorMap?: EventColor;
  color?: CheckboxColor;
  children?: React.ReactChild;
  preventDragEvents?: boolean;
}

const disabledSlash =
  'relative before:content-[""] before:absolute before:h-2.5 before:w-0.5 before:bg-blue-800 dark:before:bg-blue-200 tranform before:rotate-45 before:rounded-sm';

const Checkbox = React.forwardRef(CheckboxComponent);

function CheckboxComponent(
  {
    className,
    onChange,
    value,
    defaultChecked,
    variant = 'base',
    disabled = false,
    size = 16,
    colorMap,
    color,
    children,
    preventDragEvents = false,
  }: Props,
  ref: React.Ref<HTMLInputElement>
): ReactElement {
  const isSmallCheckbox = size < 10;

  const defaultEventClassnames = useMemo(() => {
    return {
      'border-none hover:shadow-checkbox-blue-800 dark:hover:shadow-checkbox-opacity-70':
        !disabled,
      [disabledSlash]: disabled && !value, // don't show if it's checked, because the icons will collide
      'cursor-not-allowed': disabled,
      'shadow-checkbox-blue-800 dark:shadow-checkbox-blue-200 shadow-checkbox-opacity-70':
        true,
      'bg-blue-800 dark:bg-blue-200': value,
    };
  }, [disabled, value]);

  const defaultSelectedEventClassnames = useMemo(() => {
    return {
      'hover:shadow-checkbox-opacity-70': !disabled,
      [disabledSlash]: disabled && !value, // don't show if it's checked, because the icons will collide
      'cursor-not-allowed': disabled,
      'shadow-checkbox-200 dark:shadow-checkbox-blue-800 shadow-checkbox-opacity-70':
        true,
      'bg-white/40 dark:bg-white/40 shadow-checkbox-0': value,
    };
  }, [disabled, value]);

  const baseVariantClassnames = useMemo(() => {
    const generalClassnames = {
      [disabledSlash]: disabled && !value,
      'cursor-not-allowed': disabled,
      'rounded-md': true,
    }; // don't show if it's checked, because the icons will collide
    switch (color) {
      case ColorFamily.Blue:
        return {
          ...generalClassnames,
          'hover:shadow-checkbox-blue-200': true,
          'shadow-checkbox-gray-300 dark:shadow-checkbox-gray-500': !value,
          'bg-blue-500 shadow-checkbox-blue-500': value,
        };
      case ColorFamily.Red:
        return {
          ...generalClassnames,
          'hover:shadow-checkbox-red-200': true,
          'shadow-checkbox-gray-300 dark:shadow-checkbox-gray-500': !value,
          'bg-red-500 shadow-checkbox-red-500': value,
        };

      default:
        return {
          ...generalClassnames,
          'shadow-checkbox-blue-800 dark:shadow-checkbox-blue-200 shadow-checkbox-opacity-70':
            true,
          'hover:shadow-checkbox-blue-800 dark:hover:shadow-checkbox-opacity-70':
            !disabled,
          'shadow-checkbox-gray-300 dark:shadow-checkbox-gray-500': !value,
          'bg-blue-800 dark:bg-blue-200': value,
        };
    }
  }, [color, value, disabled]);

  const variantStyles = {
    event: colorMap
      ? {
          [colorMap.scheduleCheckbox]: !value,
          [colorMap.scheduleCheckboxChecked]: value,
          [colorMap.scheduleCheckboxSlash]: disabled && !value, // don't show slash if it's checked, because the icons will collide
          'cursor-not-allowed': disabled,
        }
      : defaultEventClassnames,
    selectedevent: colorMap
      ? {
          'cursor-not-allowed': disabled,
          [colorMap.scheduleCheckboxSelected]: !value,
          [colorMap.scheduleCheckboxSelectedChecked]: value,
          [colorMap.scheduleCheckboxSlashSelected]: disabled && !value, // don't show if it's checked, because the icons will collide
        }
      : defaultSelectedEventClassnames,
    base: baseVariantClassnames,
  };

  return (
    // eslint-disable-next-line
    <label
      // Prevent dragging for an event being fired
      {...(preventDragEvents ? preventDragEventsProps : {})}
      className={classNames(
        'flex cursor-pointer items-center transition-all',
        className
      )}
      role="presentation"
    >
      <div
        className={classNames(
          'relative flex items-center justify-center transition-colors',
          {
            ...variantStyles[variant],
            'rounded-sm': isSmallCheckbox,
            rounded: variant !== 'base' && !isSmallCheckbox,
            'rounded-md': variant === 'base' && !isSmallCheckbox,
          }
        )}
        style={{ height: size, width: size }}
      >
        {isSmallCheckbox ? (
          <span
            className="absolute h-full w-full"
            style={{ left: -6, top: -2, height: size + 2, width: size + 8 }}
          />
        ) : (
          <span
            className="absolute h-full w-full"
            style={{ height: size + 6, width: size + 6 }}
          />
        )}

        <Hidden>
          <input
            ref={ref}
            className="appearance-none"
            type="checkbox"
            defaultChecked={defaultChecked}
            checked={value}
            aria-checked={value}
            disabled={disabled}
            onChange={onChange}
            {...PreventAutocompleteAttributes}
          />
        </Hidden>
        <div
          className={classNames(
            'transition-transform delay-75',
            {
              'scale-1': value,
              'scale-0': !value,
            },
            colorMap
              ? {
                  [colorMap.scheduleCheckboxIcon]: variant === 'event',
                  [colorMap.scheduleCheckboxSelectedIcon]:
                    variant === 'selectedevent',
                }
              : {
                  'dark:text-blue-900': variant === 'event',
                  'text-primary dark:text-blue-900':
                    variant === 'selectedevent',
                }
          )}
        >
          <IconCheckboxMark height={size} width={size} />
        </div>
      </div>
      {children && isReactText(children) ? (
        <span className="ml-2 cursor-pointer text-xs">{children}</span>
      ) : (
        children
      )}
    </label>
  );
}

export default Checkbox;

const stopPropagation: PointerEventHandler<HTMLLabelElement> = (event) =>
  event.stopPropagation();

const preventDragEventsProps = {
  onPointerDown: stopPropagation,
  onMouseDown: stopPropagation,
  onMouseUp: stopPropagation,
  onClick: stopPropagation,
};
