import { DragEndEvent, DragStartEvent, useDndMonitor } from '@dnd-kit/core';
import { optimisticEventsFamily } from 'hooks/events/eventAtoms';
import { useUpdateGridEvent } from 'hooks/events/useUpdateGridEvent';
import { useAtomCallback, useUpdateAtom } from 'jotai/utils';
import { useCallback } from 'react';
import { DraggableType, DroppableId } from 'types/drag-and-drop';
import { IGridEvent } from 'types/events';
import { v4 as uuid } from 'uuid';
import { useTodosDnd } from './todos-dnd';
import {
  clearDragAtom,
  dragAtom,
  dragEventAtom,
  dragOverAtom,
  dragOverCategoryAtom,
  optimisticTodosAtom,
} from './todosAtoms';
import { useUpdateTodos } from './useUpdateTodos';

export function UseTodosDnd() {
  const { deleteGridEvent } = useUpdateGridEvent();

  const optUpdate = useUpdateAtom(optimisticTodosAtom);
  const update = useUpdateTodos();

  const clearDragAtoms = useUpdateAtom(clearDragAtom);

  // TODO This goes into hook
  // Handle dropping event into schedule
  const handleEventDragEnd = useAtomCallback(
    useCallback(
      async (get, set, { active }: DragEndEvent) => {
        const dragOver = get(dragOverAtom);

        const activeData = active?.data.current;
        if (activeData && activeData.type === DraggableType.EVENT) {
          const event = activeData.event as IGridEvent;

          if (dragOver == null || dragOver.type == null) {
            // Reset event local update to put it back to grid
            set(optimisticEventsFamily(event.id), null);
            clearDragAtoms();
          } else {
            const dragOverCategory = get(dragOverCategoryAtom);

            if (dragOverCategory == null) {
              throw Error(
                'Unable to drop event into schedule. Drag Over Category is null'
              );
            }

            const getAfter = () => {
              if (dragOver.type === DraggableType.TODO_PLACEHOLDER) {
                const len = dragOverCategory.todos.length;

                return len > 0 ? dragOverCategory.todos[len - 1].id : null;
              } else if (dragOver.type === DraggableType.TODO) {
                const dragOverIndex = dragOverCategory.todos.findIndex(
                  (todo) => todo.id === dragOver.id
                );

                return dragOverIndex > 0
                  ? dragOverCategory.todos[dragOverIndex - 1].id
                  : null;
              } else if (dragOver.type === DraggableType.TODO_CATEGORY) {
                const len = dragOverCategory.todos.length;
                return len === 0 ? null : dragOverCategory.todos[len - 1].id;
              }
              return null;
            };

            const objects = {
              todos: [
                {
                  id: uuid(),
                  name: event.title,
                  doneAt: event.doneAt,
                  lastClientUpdate: new Date().toISOString(),
                  after: getAfter(),
                  categoryId: dragOverCategory.id,
                },
              ],
            };

            update(objects);
            await optUpdate(objects);

            deleteGridEvent({
              eventId: event.id,
              calendarId: event.calendarId,
            });
            clearDragAtoms();
          }
        }
      },
      [clearDragAtoms, deleteGridEvent, optUpdate, update]
    )
  );

  const handleGridDragStart = useAtomCallback(
    useCallback((_, set, event: DragStartEvent) => {
      const activeData = event.active.data.current;
      if (activeData == null) return;

      if (
        activeData.type === DraggableType.EVENT &&
        activeData.event.title?.length > 0
      ) {
        set(dragAtom, { type: DraggableType.EVENT, id: activeData.event.id });
        set(dragEventAtom, activeData.event);
      }
    }, [])
  );

  const handleGridDragEnd = useCallback(
    (event: DragEndEvent) => {
      const active = event.active;
      const over = event.over;
      if (over && over.id === DroppableId.SCHEDULE) {
        clearDragAtoms();
        return;
      }

      if (active) {
        const activeData = active?.data.current;
        if (activeData && activeData.type === DraggableType.EVENT) {
          handleEventDragEnd(event);
        }
      }
    },
    [clearDragAtoms, handleEventDragEnd]
  );

  useDndMonitor({
    onDragStart: handleGridDragStart,
    onDragEnd: handleGridDragEnd,
  });

  useTodosDnd();
  return null;
}
