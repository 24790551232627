import { useAtomValue } from 'jotai/utils';
import React from 'react';
import { createPortal } from 'react-dom';
import { isDraggingAtom } from './todos-dnd';
import { useMousePosition } from './utils';

export type DragOverlayProps = { children: React.ReactNode };

export function TodosDragOverlay({ children }: DragOverlayProps) {
  const { x, y } = useMousePosition();

  const isDragging = useAtomValue(isDraggingAtom);
  if (!isDragging) return null;

  return createPortal(
    <div
      className="pointer-events-none absolute z-200 bg-opacity-30"
      style={{
        transform: `translate3d(calc(${x}px - 50%), calc(${y}px), 0)`,
      }}
    >
      <div className="relative">{children}</div>
    </div>,
    document.body
  );
}
