/* 0dd7f23e14fcf4594315f7a8870131f443c96e81
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateEventMutationVariables = Types.Exact<{
  eventId: Types.Scalars['ID'];
  title?: Types.InputMaybe<Types.Scalars['String']>;
  description?: Types.InputMaybe<Types.Scalars['String']>;
  location?: Types.InputMaybe<Types.Scalars['String']>;
  colorFamily?: Types.InputMaybe<Types.ColorFamily>;
  calendarId: Types.Scalars['String'];
  startAt?: Types.InputMaybe<Types.Scalars['DateTimeRFC3339']>;
  endAt?: Types.InputMaybe<Types.Scalars['DateTimeRFC3339']>;
  attendeesEmailAddresses?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  addVideoConference?: Types.InputMaybe<Types.NewVideoConferenceProvider>;
  visibility?: Types.InputMaybe<Types.NewEventVisibilityEnum>;
  rsvp?: Types.InputMaybe<Types.NewEventRsvpEnum>;
  isAllDay?: Types.InputMaybe<Types.Scalars['Boolean']>;
  recurringEventId?: Types.InputMaybe<Types.Scalars['String']>;
  recurrenceRules?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  notifyGuests?: Types.InputMaybe<Types.Scalars['Boolean']>;
  notifyMessage?: Types.InputMaybe<Types.Scalars['String']>;
  doneAt?: Types.InputMaybe<Types.Scalars['DateTimeRFC3339']>;
}>;


export type UpdateEventMutation = { __typename?: 'mutation_root', updateEvent: { __typename?: 'UpdateEventOutput', success: boolean, willNotify: boolean, event?: { __typename?: 'NewEvent', calendarId: string, description?: string | null, title: string, id: string, createdAt: string, doneAt?: string | null, doneBy?: string | null, location?: string | null, colorFamily?: Types.ColorFamily | null, rsvp: Types.NewEventRsvpEnum, isOwnEvent: boolean, canEdit: boolean, isSelfAsAttendee: boolean, belongsToUserCalendar: boolean, eventType?: string | null, recurringEventId?: string | null, recurrenceRules?: Array<string> | null, startAt: any, endAt: any, isAllDay: boolean, prevStartAt: any, prevEndAt: any, status: string, visibility: Types.NewEventVisibilityEnum, attendees: Array<{ __typename?: 'NewEventAttendee', avatar?: string | null, displayName?: string | null, firstName?: string | null, id: string, lastName?: string | null, RSVP: Types.NewEventRsvpEnum, email: string, organizer: boolean, responseStatus: Types.NewEventAttendeeResponseStatusEnum }>, creator?: { __typename?: 'EventCreator', id?: string | null, self?: boolean | null } | null, videoConferences: Array<{ __typename?: 'NewEventVideoConference', link: string, provider: Types.NewVideoConferenceProvider }> } | null } };

export type EventFragmentFragment = { __typename?: 'NewEvent', calendarId: string, description?: string | null, title: string, id: string, createdAt: string, doneAt?: string | null, doneBy?: string | null, location?: string | null, colorFamily?: Types.ColorFamily | null, rsvp: Types.NewEventRsvpEnum, isOwnEvent: boolean, canEdit: boolean, isSelfAsAttendee: boolean, belongsToUserCalendar: boolean, eventType?: string | null, recurringEventId?: string | null, recurrenceRules?: Array<string> | null, startAt: any, endAt: any, isAllDay: boolean, prevStartAt: any, prevEndAt: any, status: string, visibility: Types.NewEventVisibilityEnum, attendees: Array<{ __typename?: 'NewEventAttendee', avatar?: string | null, displayName?: string | null, firstName?: string | null, id: string, lastName?: string | null, RSVP: Types.NewEventRsvpEnum, email: string, organizer: boolean, responseStatus: Types.NewEventAttendeeResponseStatusEnum }>, creator?: { __typename?: 'EventCreator', id?: string | null, self?: boolean | null } | null, videoConferences: Array<{ __typename?: 'NewEventVideoConference', link: string, provider: Types.NewVideoConferenceProvider }> };

export const EventFragmentFragmentDoc = gql`
    fragment EventFragment on NewEvent {
  attendees {
    avatar
    displayName
    firstName
    id
    lastName
    RSVP
    email
    organizer
    responseStatus
  }
  calendarId
  description
  title
  id
  createdAt
  creator {
    id
    self
  }
  doneAt
  doneBy
  location
  colorFamily
  rsvp
  isOwnEvent
  canEdit
  isSelfAsAttendee
  belongsToUserCalendar
  eventType
  recurringEventId
  recurrenceRules
  startAt
  endAt
  isAllDay
  prevStartAt
  prevEndAt
  status
  videoConferences {
    link
    provider
  }
  visibility
}
    `;
export const UpdateEventDocument = gql`
    mutation UpdateEvent($eventId: ID!, $title: String, $description: String, $location: String, $colorFamily: ColorFamily, $calendarId: String!, $startAt: DateTimeRFC3339, $endAt: DateTimeRFC3339, $attendeesEmailAddresses: [String!], $addVideoConference: NewVideoConferenceProvider, $visibility: NewEventVisibilityEnum, $rsvp: NewEventRSVPEnum, $isAllDay: Boolean, $recurringEventId: String, $recurrenceRules: [String!], $notifyGuests: Boolean, $notifyMessage: String, $doneAt: DateTimeRFC3339) {
  updateEvent(
    objects: {eventId: $eventId, title: $title, description: $description, location: $location, colorFamily: $colorFamily, calendarId: $calendarId, startAt: $startAt, endAt: $endAt, attendeesEmailAddresses: $attendeesEmailAddresses, addVideoConference: $addVideoConference, visibility: $visibility, rsvp: $rsvp, isAllDay: $isAllDay, recurringEventId: $recurringEventId, recurrenceRules: $recurrenceRules, notifyGuests: $notifyGuests, notifyMessage: $notifyMessage, doneAt: $doneAt}
  ) {
    success
    willNotify
    event {
      ...EventFragment
    }
  }
}
    ${EventFragmentFragmentDoc}`;

export function useUpdateEventMutation() {
  return Urql.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument);
};