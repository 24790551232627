import IconCalendar from 'components/Icons/IconCalendar';
import {
  ListItemAction,
  NavItemChronoActions,
  NavItemType,
} from 'components/QuickMenu/types';
import { hoveredTodoIdAtom, useUpdateTodo } from 'components/Todos/TodoItem';
import { TodosQuery } from 'graphql/queries/todos.graphql';
import { getVisibilityAsEnum } from 'hooks/events/helpers/eventsHelpers';
import {
  draftEvent,
  useUpdateGridEvent,
} from 'hooks/events/useUpdateGridEvent';
import { preferencesAtom } from 'hooks/preferences/preferencesAtoms';
import { useUpdateModal } from 'hooks/useModal';
import { userAtom } from 'hooks/user/userAtoms';
import { useAtomCallback, useUpdateAtom } from 'jotai/utils';
import { Duration } from 'luxon';
import React from 'react';
import { ChronoGroupResult } from '../NestedQuickMenuGroups/useChronoGroup';
import useSetNavItems from '../useSetNavItems';

interface Props {
  todoItem: TodosQuery['todos'][0] | undefined;
}

export const useScheduleIntoCalendar = ({
  todoItem,
}: Props): ListItemAction => {
  const { addNavItem } = useSetNavItems();
  const { closeModal } = useUpdateModal();
  const updateTodo = useUpdateTodo(todoItem?.id);
  const updateHoveredTodoId = useUpdateAtom(hoveredTodoIdAtom);

  const { createGridEventFromProps, deleteDraftEvent } = useUpdateGridEvent();

  const getUserPreferences = useAtomCallback((get) => get(preferencesAtom));
  const getUser = useAtomCallback((get) => get(userAtom));

  return {
    id: 'scheduleIntoCalendar',
    title: 'Schedule into calendar',
    keywords: ['create', 'todo'],
    type: 'action',
    actionOverride: () => {
      const navItem: NavItemChronoActions = {
        type: NavItemType.chronoActions,
        title: `Choose date to schedule`,
        inputPlaceholder: 'Choose date to schedule',
        hidden: true,
        callback: (selection: ChronoGroupResult) => {
          closeModal();
          const startAt = selection.start;

          (async () => {
            const user = await getUser();
            const userPreferences = await getUserPreferences();
            if (!todoItem?.name || !user?.email) return;

            // Delete any existing draft event before scheduling the todo.
            await deleteDraftEvent();
            await createGridEventFromProps({
              ...draftEvent,
              title: todoItem.name,
              startAt,
              createdAt: new Date(),
              calendarId: user.email,
              endAt: startAt.plus(Duration.fromObject({ minutes: 30 })),
              visibility: getVisibilityAsEnum(userPreferences?.todoPrivacy),
              colorFamily: userPreferences.defaultEventColor,
              attendees: [],
              isAllDay: false,
            });

            await updateTodo({ deletedAt: new Date().toISOString() });
            updateHoveredTodoId(undefined);
          })().catch(console.error);
        },
      };

      addNavItem({
        type: navItem.type,
        fullObject: navItem,
      });
    },
    Icon: () => <IconCalendar className="h-5 w-5 p-0.5 text-gray-400" />,
  };
};
