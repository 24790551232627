import {
  Category,
  ColorFamily,
  ColorFamilyEnum_Enum,
  NewEventRsvpEnum,
  NewEventVisibilityEnum,
} from '@graphql-types@';
import { throttle } from 'frame-throttle';
import {
  accentColorAtom,
  showDoneTodosAtom,
} from 'hooks/preferences/preferencesAtoms';
import { atom } from 'jotai';
import { atomFamily, useAtomValue } from 'jotai/utils';
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { DraggableType } from 'types/drag-and-drop';
import { IGridEvent } from 'types/events';
import {
  activeCategoryIdAtom,
  activeTodoIdAtom,
  dragAtom,
  dragEventAtom,
  dragOverAtom,
  dragTodoAtom,
  normalizedCategoriesAtom,
  normalizedTodosAtom,
  showArchivedListsAtom,
} from './todosAtoms';
import { ColorMapKey, TodoCategoryT, TodoItemT } from './types';

export function colorFamilyToColor(
  colorFamily: Category['colorFamily']
): ColorMapKey {
  const family = colorFamily || ColorFamilyEnum_Enum.Gray;

  return family.toLocaleLowerCase() as ColorMapKey;
}

const isDraggingOverAtomFamily = atomFamily(
  ({ id, types }: { id: string; types?: DraggableType[] }) =>
    atom<boolean>((get) => {
      const dragOver = get(dragOverAtom);
      if (dragOver == null) return false;

      if (types !== undefined && types.length > 0) {
        const dragItem = get(dragAtom);
        if (!dragItem || !dragItem.type || !types.includes(dragItem.type)) {
          return false;
        }
      }

      return id === dragOver.id;
    }),
  (a, b) => a.id === b.id && isEqual(a.types, b.types)
);

export function useIsDraggingOver(
  itemId: string,
  types?: DraggableType[]
): boolean {
  return useAtomValue(isDraggingOverAtomFamily({ id: itemId, types }));
}

const isDraggingAtomFamily = atomFamily((id: string) =>
  atom<boolean>((get) => {
    const drag = get(dragAtom);

    return id === drag?.id;
  })
);

export function useIsDragging(id: string): boolean {
  return useAtomValue(isDraggingAtomFamily(id));
}

const isActiveTodoIdAtomFamily = atomFamily((id: string) =>
  atom<boolean>((get) => get(activeTodoIdAtom) === id)
);

export function useIsActiveTodoId(itemId: string): boolean {
  return useAtomValue(isActiveTodoIdAtomFamily(itemId));
}
const isActiveCategoryIdAtomFamily = atomFamily((id: string) =>
  atom<boolean>((get) => get(activeCategoryIdAtom) === id)
);

export function useIsActiveCategoryId(categoryId: string): boolean {
  return useAtomValue(isActiveCategoryIdAtomFamily(categoryId));
}

export const isDraggingEventAtom = atom((get) => Boolean(get(dragEventAtom)));
export const isDraggingTodoAtom = atom((get) => Boolean(get(dragTodoAtom)));

export const categoryFamily = atomFamily((id: string) =>
  atom((get) => get(normalizedCategoriesAtom)[id])
);

export const todoFamily = atomFamily((id: string) =>
  atom((get) => get(normalizedTodosAtom)[id])
);

export const categoryColorAtomFamily = atomFamily((id: string) =>
  atom((get) => {
    const category = get(categoryFamily(id));

    return category?.colorFamily
      ? colorFamilyToColor(category.colorFamily)
      : undefined;
  })
);

export const useCategoryColor: (id: string) => ColorFamily | undefined = (id) =>
  useAtomValue(categoryColorAtomFamily(id));

export const categoryTodosIdsFamily = atomFamily((id: string) =>
  atom<Array<string>>((get) => {
    const showDoneTodos = get(showDoneTodosAtom);
    const showArchivedLists = get(showArchivedListsAtom);
    const todos = get(normalizedTodosAtom);
    const category = get(categoryFamily(id));
    const showDone = showArchivedLists || showDoneTodos;

    const ids = category.todos.map(({ id }) => id);

    return showDone ? ids : ids.filter((id) => !todos[id].doneAt);
  })
);

export function useCategoryTodosForDisplay(categoryId: string): Array<string> {
  return useAtomValue(categoryTodosIdsFamily(categoryId));
}

export function useTodo(id: string): TodoItemT {
  return useAtomValue(todoFamily(id));
}

export function useCategory(id: string): TodoCategoryT {
  return useAtomValue(categoryFamily(id));
}

export const dropStyleColorMap: Record<ColorFamily, string[]> = {
  [ColorFamily.Gray]: [`border-gray-800/50`, `dark:border-gray-200`],
  [ColorFamily.Red]: [`border-red-800/50`, `dark:border-red-200`],
  [ColorFamily.Orange]: [`border-orange-800/50`, `dark:border-orange-200`],
  [ColorFamily.Yellow]: [`border-yellow-800/50`, `dark:border-yellow-200`],
  [ColorFamily.Green]: [`border-green-800/50`, `dark:border-green-200`],
  [ColorFamily.Blue]: [`border-blue-800/50`, `dark:border-blue-200`],
  [ColorFamily.Purple]: [`border-purple-800/50`, `dark:border-purple-200`],
  [ColorFamily.Pink]: [`border-pink-800/50`, `dark:border-pink-200`],
};

const DEFAULT_MOUSE_POSITION = {
  x: -1000,
  y: 0,
};

export function useMousePosition(): { x: number; y: number } {
  const [mousePosition, setMousePosition] = useState<{ x: number; y: number }>(
    DEFAULT_MOUSE_POSITION
  );

  useEffect(() => {
    const handler = throttle((event: MouseEvent) =>
      setMousePosition({ x: event.clientX, y: event.clientY })
    );
    document.addEventListener('mousemove', handler);
    return () => {
      setMousePosition(DEFAULT_MOUSE_POSITION);
      document.removeEventListener('mousemove', handler);
    };
  }, []);

  return mousePosition;
}

type BoxRange = {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
};

const isInRange: (box: BoxRange, event: MouseEvent) => boolean = (
  { x, y, width, height },
  { clientX, clientY }
) => {
  if (x != null && clientX < x) return false;
  if (y != null && clientY < y) return false;
  if (x != null && width != null && clientX > x + width) return false;
  if (y != null && height != null && clientY > y + height) return false;
  return true;
};

export function useIsMouseInRange(box: BoxRange): boolean {
  const [inRange, setInRange] = useState<boolean>(false);

  useEffect(() => {
    const handler = (event: MouseEvent) => setInRange(isInRange(box, event));

    document.addEventListener('mousemove', handler, { passive: true });
    return () => document.removeEventListener('mousemove', handler);
  });

  return inRange;
}

export const eventDefaults = {
  id: '',
  calendarId: '',
  attendees: [],
  isSelfAsAttendee: true,
  isOwnEvent: true,
  dayIndex: 1,
  createdAt: DateTime.now().set({ hour: 1 }).toJSDate(),
  startAt: DateTime.now().set({ hour: 7 }),
  endAt: DateTime.now().set({ hour: 8 }),
  allOtherGuestsDeclined: false,
  location: '',
  videoConferences: [],
  description: '',
  canEdit: true,
  belongsToUserCalendar: true,
  prevEndAt: DateTime.now().set({ hour: 8 }),
  prevStartAt: DateTime.now().set({ hour: 7 }),
  isAllDay: false,
  rsvp: NewEventRsvpEnum.Yes,
  visibility: NewEventVisibilityEnum.Default,
  isDraft: false,
  status: NewEventRsvpEnum.Yes,
};
