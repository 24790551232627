import IconCalendar from 'components/Icons/IconCalendar';
import IconHome from 'components/Icons/IconHome';
import IconPreferences from 'components/Icons/IconPreferences';
import IconWhatsNew from 'components/Icons/IconWhatsNew';
import IconFeedback from 'components/Icons/IconFeedback';
import { SettingsPageType } from 'components/Settings/types';
import { useUpdateCalendar } from 'hooks/useCalendar';
import { useIsModalOpen, useUpdateModal } from 'hooks/useModal';
import { useRouter } from 'next/router';
import React, { useCallback, useRef } from 'react';
import { ModalType } from 'types/modal';
import { isHomePage, isRootPage } from 'utils/router';
import {
  HookProps,
  ListGroup,
  ListItemAction,
  ListItemNavigation,
  NavItemChronoActions,
  NavItemType,
} from '../types';
import { getNavigationResults, isSearching } from '../utils';
import { ChronoGroupResult } from './NestedQuickMenuGroups/useChronoGroup';
import { filterBySearch } from './useListGroups';
import useSetNavItems from './useSetNavItems';

export default function useNavigationActions({
  enabled,
  eventIds,
  searchTerm,
}: HookProps): ListGroup {
  const router = useRouter();
  const { openModal, closeModal } = useUpdateModal();
  const isSettingsModalOpen = useIsModalOpen(ModalType.Settings);

  const { addNavItem } = useSetNavItems();
  const { setStartDate } = useUpdateCalendar();

  const listGroupRef = useRef<ListGroup>({
    title: 'Navigation',
    items: [],
  });
  listGroupRef.current.items.length = 0;

  const navigateToSpecificDate = useCallback(() => {
    const navItem: NavItemChronoActions = {
      type: NavItemType.chronoActions,
      title: `Choose date`,
      inputPlaceholder: 'Choose date',
      hidden: true,
      calendarDatesOnly: true,
      getActionNameOverride: () => 'Select',
      callback: (selection: ChronoGroupResult) => {
        router.push('/');
        setStartDate(selection.start);
        closeModal();
      },
    };

    addNavItem({
      type: navItem.type,
      fullObject: navItem,
    });
  }, [addNavItem, closeModal, router, setStartDate]);

  const options: (ListItemAction | ListItemNavigation)[] = [];

  if (!isRootPage(router)) {
    options.push({
      id: 'navigate-to-calendar',
      title: 'Go to calendar',
      keywords: ['navigate', 'calendar'],
      type: 'action',
      actionOverride: () => {
        closeModal();
        router.push('/');
      },
      Icon: () => <IconCalendar className="h-5 w-5 p-0.5 text-gray-400" />,
    });
  }

  if (!isHomePage(router)) {
    options.push({
      id: 'navigate-to-home',
      title: 'Go to home',
      keywords: ['navigate', 'home'],
      type: 'action',
      actionOverride: () => {
        closeModal();
        router.push('/home');
      },
      Icon: () => <IconHome className="h-5 w-5 p-0.5 text-gray-400" />,
    });
  }

  if (!isSettingsModalOpen) {
    options.push({
      id: 'navigate-to-settings',
      title: 'Go to settings',
      keywords: ['navigate', 'settings'],
      type: 'action',
      actionOverride: () => {
        closeModal();
        openModal(ModalType.Settings);
      },
      Icon: () => <IconPreferences className="h-5 w-5 p-0.5 text-gray-400" />,
    });
  }

  if (!eventIds?.length) {
    options.push({
      id: 'navigateToSpecificDate',
      title: 'Jump to date',
      keywords: ['slot', 'share'],
      type: 'action',
      actionOverride: navigateToSpecificDate,
      Icon: () => <IconCalendar className="h-5 w-5 p-0.5 text-gray-400" />,
      onlyAppearsInSearch: true,
    });
  }

  if (!enabled || eventIds?.length) return listGroupRef.current;

  if (isSearching(searchTerm)) {
    const searchedPages = getNavigationResults({ searchTerm, options });

    searchedPages.map((navPage) => {
      listGroupRef.current.items.push(navPage);
    });
  }

  options.push(
    {
      id: 'feedback',
      title: 'Send feedback',
      keywords: ['send', 'feedback'],
      type: 'action',
      actionOverride: () => {
        openModal(ModalType.SendFeedback);
      },
      Icon: () => <IconFeedback className="h-5 w-5 p-0.5 text-gray-400" />,
    },
    {
      id: 'whats new',
      title: "What's new",
      page: SettingsPageType.WhatsNew,
      type: 'navigation',
      Icon: () => <IconWhatsNew className="h-5 w-5 p-0.5 text-gray-400" />,
    }
  );

  listGroupRef.current.items = filterBySearch(options, searchTerm);
  return listGroupRef.current;
}
