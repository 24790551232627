import classNames from 'classnames';
import type { PropsOf } from 'joy/utils';
import React, { useMemo, useRef } from 'react';
import IconEnter from './icons/IconEnter';
import type { ListItem } from './types';
import { getActionName } from './utils';

interface Props extends PropsOf<'div'> {
  selected: boolean;
  item: ListItem;
}

export default React.forwardRef(function QuickMenuItem(
  { selected, item, ...rest }: Props,
  ref: React.Ref<HTMLDivElement>
): JSX.Element {
  const actionName = getActionName(item);
  const itemDescription = 'description' in item && item.description;

  const leftHalfRef = useRef<HTMLDivElement>(null);

  const isTextOverflow = useMemo(() => {
    if (!leftHalfRef.current) return false;

    const parentWidth = leftHalfRef.current.getBoundingClientRect().width;
    let childrenWidths = 0;

    Array.from(leftHalfRef.current.children).forEach(
      (x) => (childrenWidths += x.getBoundingClientRect().width)
    );

    // Check if the width + a buffer exceeds the parent container's width.
    return childrenWidths + 25 >= parentWidth;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, selected, actionName]);

  return (
    <div ref={ref} className="mx-2 -my-2 flex py-2" {...rest}>
      <div
        className={classNames(
          'flex w-full cursor-pointer items-center justify-between rounded-lg px-3 py-3 text-sm',
          {
            'bg-gray-100 dark:bg-gray-700': selected,
          }
        )}
      >
        <div
          className={classNames('flex w-9/12 items-center space-x-2.5', {
            'half-fade-bg-right': isTextOverflow,
          })}
          ref={leftHalfRef}
        >
          <item.Icon />
          <p className="text-primary truncate font-medium">{item.title}</p>
          {'time' in item && item.time && (
            <p className="text-secondary font-medium tabular-nums">
              {item.time}
            </p>
          )}
          {itemDescription && (
            <p className="text-secondary text-clip whitespace-nowrap font-medium">
              {itemDescription}
            </p>
          )}
        </div>

        {selected && actionName && (
          <div className="flex items-center space-x-1.5">
            <p className="text-secondary text-xs font-semibold">{actionName}</p>
            <div className="text-secondary flex">
              <IconEnter />
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
