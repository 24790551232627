/* eslint-disable react/display-name */
import IconCalendar from 'components/Icons/IconCalendar';
import { useCalendarColor } from 'components/Panels/useAvailableCalendars';
import { useVisibleEvents } from 'hooks/events/useGridEvents';
import { useAccentColor } from 'hooks/usePreferences';
import React, { useRef } from 'react';
import { IGridEvent } from 'types/events';
import { colorOrDefault } from 'utils/eventColors';
import { isPast } from 'utils/time';
import { getVideoProvider, handleVideoClick } from 'utils/video';
import IconEvent from '../icons/IconEvent';
import { HookProps, ListGroup, ListItemUpcoming } from '../types';
import { getNextVisibleEvent } from '../utils';

export default function useUpcomingEvent({ enabled }: HookProps): ListGroup {
  const events: IGridEvent[] = useVisibleEvents();

  const listGroupRef = useRef<ListGroup>({ title: 'Upcoming', items: [] });
  listGroupRef.current.items.length = 0;
  const upcomingEvent = getNextVisibleEvent(enabled ? events : [], true);
  const calendarColor = useCalendarColor(upcomingEvent?.calendarId);

  if (!enabled) return listGroupRef.current;
  if (!upcomingEvent) return listGroupRef.current;

  if (isPast(upcomingEvent.startAt)) {
    listGroupRef.current.title = 'Now';
  }
  const color = upcomingEvent.colorFamily || calendarColor;

  const listItem: ListItemUpcoming = {
    id: 'upcomingEvent',
    title: upcomingEvent.title,
    description: `${upcomingEvent.startAt.toFormat(
      'HH:mm'
    )} - ${upcomingEvent.endAt.toFormat('HH:mm')}`,
    type: 'upcomingEvent',
    Icon: () => (
      <IconCalendar
        className={`h-5 w-5 p-0.5 text-${color}-400 dark:text-${color}-400 text-opacity-75`}
      />
    ),
  };

  if (upcomingEvent.location) {
    const location = upcomingEvent.location;
    const providerName = getVideoProvider(location);

    listItem.actionNameOverride = providerName
      ? `Join ${providerName}`
      : undefined;

    listItem.actionOverride = () => handleVideoClick({ url: location });
  }

  listGroupRef.current.items.push(listItem);

  return listGroupRef.current;
}
