import { Category, ColorFamily, ColorFamilyEnum_Enum } from '@graphql-types@';
import classNames from 'classnames';
import ToggleIcon from 'components/Icons/ToggleIcon';
import { PreventAutocompleteAttributes } from 'components/PreventAutocompleteAttributes';
import useHotkey from 'hooks/useHotkey';
import { useAtomCallback, useAtomValue } from 'jotai/utils';
import Button from 'joy/Button';
import Tooltip from 'joy/Tooltip';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';
import { EVENT_COLOR_MAP } from 'utils/eventColors';
import CategoryOptions from './CategoryOptions';
import { DRAFT, TOP } from './constants';
import { DragHandle } from './DragHandle';
import { isDraggingAtom } from './todos-dnd';
import {
  activeCategoryIdAtom,
  activeTodoIdAtom,
  draftCategoryIdAtom,
  todoDraftPositionAtom,
} from './todosAtoms';
import { useSetInputFocus } from './useFocusControl';
import { colorFamilyToColor } from './utils';

export interface CategoryHeaderProps {
  expanded?: boolean;
  setExpanded?: () => void;
  colorFamily?: Category['colorFamily'];
  icon?: JSX.Element | null;
  isDraft?: boolean;
  onArchive?: () => void;
  onDelete?: () => void;
  onColorChange: (value: ColorFamily) => void;
  showCategoryOptions?: boolean;
  onBlurCapture: (
    event: React.FocusEvent<HTMLTextAreaElement, Element>
  ) => void;
  shouldAutoFocus?: boolean;
  categoryId: string;
  defaultValue?: string;
  handleRef?: React.MutableRefObject<HTMLElement | null>;
}

type Props = Omit<CategoryHeaderProps & TextareaAutosizeProps, 'value'>;

const CategoryHeaderComponent = ({
  expanded,
  setExpanded,
  defaultValue,
  colorFamily,
  icon,
  placeholder,
  onArchive,
  onDelete,
  onColorChange,
  showCategoryOptions,
  shouldAutoFocus,
  isDraft,
  categoryId,
  handleRef,
  ...props
}: Props) => {
  const color = colorFamilyToColor(colorFamily);
  const hasColor = colorFamily && colorFamily !== ColorFamilyEnum_Enum.Gray;
  const colorMap = EVENT_COLOR_MAP[color];

  const [value, setValue] = useState(defaultValue);
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      // replace any new lines and prevent multi-line titles
      setValue(e.target.value.replace(/\n/g, ''));
    },
    []
  );

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isFocused, setFocused] = useState(false);

  useEffect(() => {
    if (shouldAutoFocus || isFocused) {
      textareaRef.current?.focus();
    }
  }, [isFocused, shouldAutoFocus]);

  const setInputFocus = useSetInputFocus();
  const isDragging = useAtomValue(isDraggingAtom);

  useHotkey(
    'escape',
    {
      scope: 'all',
      enabledWithinInput: true,
      enabled: isFocused,
    },
    () => {
      textareaRef.current?.blur();
    }
  );

  const onKeyPress = useAtomCallback(
    useCallback(
      (_, set, event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        const title = event.currentTarget.value;
        if (event.code !== 'Enter' || title.length === 0) return;
        event.preventDefault();

        if (isDraft && !title) {
          // remove draft category if it was blurred and no title was entered
          set(draftCategoryIdAtom, null);
          set(activeCategoryIdAtom, null);
          set(activeTodoIdAtom, null);
        } else if (
          defaultValue !== title &&
          (title.length > 0 || defaultValue?.length !== 0)
        ) {
          if (isDraft) {
            set(todoDraftPositionAtom, TOP); // focus on the first draft todo
            // order here is important
            // set(draftCategoryIdAtom) should be set to null before we add the item to the list
            // that way we hide the draft category before the new one shows
            // once the draft is saved, remove draft placeholder
            set(draftCategoryIdAtom, null);
          } else {
            // if not draft, update the name and set active category to the current id
            // to set focus on the next todo item in that category
            set(activeCategoryIdAtom, categoryId);
            set(todoDraftPositionAtom, TOP); // focus on the first draft todo
          }
        }

        // pressing Enter when the cursor is at any position in the title
        // saves the title and moves focus to next todo
        textareaRef.current?.blur();
        setInputFocus({ id: DRAFT });
      },
      [categoryId, defaultValue, isDraft, setInputFocus]
    )
  );

  const onFocus = useAtomCallback(
    useCallback((_, set, event: React.FocusEvent<HTMLTextAreaElement>) => {
      setFocused(true);
      const inputLength = event.target.value?.length;
      // Put cursor at last position when focused
      event.target.setSelectionRange(inputLength, inputLength);

      // remove previous active todos and reset + Todo buttons for other categories
      set(activeTodoIdAtom, null);
    }, [])
  );

  return (
    <div className="group flex w-full items-start">
      <DragHandle
        ref={handleRef}
        className={classNames(
          colorMap.todoButtonText,
          colorMap.todoButton,
          'opacity-0 transition-opacity',
          { 'group-hover:opacity-100': !isDragging && !isDraft }
        )}
      />
      <div className="flex w-full gap-0.5">
        <Tooltip disabled={isDraft} content={expanded ? 'Collapse' : 'Expand'}>
          <Button
            disabled={isDraft}
            onClick={setExpanded}
            onMouseDown={(event: React.MouseEvent) => event.preventDefault()}
            className={classNames(
              'flex h-6 w-6 shrink-0 items-center justify-center rounded-md',
              colorMap.todoButton,
              colorMap.todoButtonText
            )}
          >
            {icon || (
              <ToggleIcon
                className={classNames('transition-transform', {
                  '-rotate-90': !expanded,
                  'text-gray-400': !hasColor,
                })}
              />
            )}
          </Button>
        </Tooltip>

        {isDraft || isFocused ? (
          <TextareaAutosize
            ref={textareaRef}
            placeholder={placeholder}
            value={value}
            onFocus={onFocus}
            onBlur={() => setFocused(false)}
            onChange={handleChange}
            onKeyPress={onKeyPress}
            className={classNames(
              'inline-flex w-full resize-none bg-transparent pt-0.5 text-sm font-semibold hover:cursor-pointer focus:cursor-auto focus:outline-none',
              colorMap.todoButtonPlaceholderText,
              colorMap.selection
            )}
            {...PreventAutocompleteAttributes}
            {...props}
          />
        ) : (
          <div
            className={classNames(
              'inline-flex w-full bg-transparent pt-0.5 text-sm font-semibold hover:cursor-pointer focus:cursor-auto focus:outline-none',
              { 'opacity-40': !value }
            )}
            onClickCapture={() => setFocused(true)}
          >
            {value || placeholder}
          </div>
        )}

        {showCategoryOptions && (
          <CategoryOptions
            menuId={categoryId}
            archiveList={onArchive}
            deleteList={onDelete}
            changeColor={onColorChange}
            colorFamily={color}
            className={classNames({
              invisible: isDragging,
            })}
          />
        )}
      </div>
    </div>
  );
};

export const CategoryHeader = React.memo(CategoryHeaderComponent);
