import { useAtomValue } from 'jotai/utils';
import { useRef } from 'react';
import { HookProps, ListGroup, NavItemType } from '../../types';
import { quickMenuNavItemsAtom } from '../useSetNavItems';

export default function useNestedActionsGroup({
  enabled,
}: HookProps): ListGroup {
  const navItems = useAtomValue(quickMenuNavItemsAtom);
  const lastNavItem = navItems[navItems.length - 1];
  const listGroupRef = useRef<ListGroup>({
    title: lastNavItem?.title || 'Nested Actions',
    items: [],
  });

  if (
    !enabled ||
    lastNavItem?.type !== NavItemType.nestedActions ||
    !lastNavItem.actions
  ) {
    listGroupRef.current.items.length = 0;
    return listGroupRef.current;
  }

  listGroupRef.current.title = lastNavItem?.title || 'Nested Actions';
  listGroupRef.current.items = lastNavItem.actions;

  return listGroupRef.current;
}
