/* 21cd2cd82f5a53041c0d069d3e144be5c9c178f3
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DeleteEventMutationVariables = Types.Exact<{
  eventId: Types.Scalars['ID'];
  calendarId?: Types.InputMaybe<Types.Scalars['String']>;
  notifyGuests?: Types.InputMaybe<Types.Scalars['Boolean']>;
  applyToFutureEvents?: Types.InputMaybe<Types.Scalars['Boolean']>;
  notifyMessage?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type DeleteEventMutation = { __typename?: 'mutation_root', deleteEvent: { __typename?: 'DeleteEventOutput', success: boolean, willNotify: boolean, eventId?: string | null } };


export const DeleteEventDocument = gql`
    mutation DeleteEvent($eventId: ID!, $calendarId: String, $notifyGuests: Boolean, $applyToFutureEvents: Boolean, $notifyMessage: String) {
  deleteEvent(
    where: {eventId: $eventId, calendarId: $calendarId, notifyMessage: $notifyMessage, applyToFutureEvents: $applyToFutureEvents}
    notifyGuests: $notifyGuests
  ) {
    success
    willNotify
    eventId
  }
}
    `;

export function useDeleteEventMutation() {
  return Urql.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument);
};