import useHotkey from 'hooks/useHotkey';
import { atom } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { TEST_ID_EVENT_RESIZE_BOTTOM } from 'utils/constants';
import { roundDateTimeToNearestQuarterHour } from 'utils/mouseEvents';
import { MINUTES_IN_A_DAY } from 'utils/time';
import { __LowPerf__getGridInteractionHandlerBoundingRect } from './clickHandler/GridInteractionsHandler';

interface Props {
  startAt: DateTime;
  endAt: DateTime;
  offset?: number;
  disabled?: boolean;
  onIsResizingChange?: React.Dispatch<React.SetStateAction<boolean>>;
  onResizeStart?(): void;
  onResize(updatedValues: { startAt: DateTime; endAt: DateTime }): void;
  onResizeCancel?(): void;
  onResizeEnd?(): void;
}

export default function GridResizeHandles({
  startAt,
  endAt,
  offset = 0,
  disabled,
  onIsResizingChange,
  onResize,
  onResizeEnd,
  onResizeStart,
  onResizeCancel,
}: Props): JSX.Element | null {
  const sideRef = useRef<'start' | 'end'>('start');

  const initialY = useRef(0);
  const gridHeight = useRef(0);
  const initialTime = useRef(startAt);
  const lockedSideTime = useRef(startAt);
  const setIsResizingEventAtom = useUpdateAtom(isResizingEventAtom);

  const [willResize, setWillResize] = useState(false);

  const onDragStart = useCallback(
    (mouseEvent: React.MouseEvent, side: 'start' | 'end') => {
      mouseEvent.preventDefault();
      mouseEvent.stopPropagation();
      gridHeight.current =
        __LowPerf__getGridInteractionHandlerBoundingRect()?.height || 1168;
      initialY.current = mouseEvent.clientY;
      initialTime.current = side === 'start' ? startAt : endAt;
      lockedSideTime.current = side === 'start' ? endAt : startAt;

      sideRef.current = side;
      setWillResize(true);
      setIsResizingEventAtom(true);
      onIsResizingChange?.(true);
      onResizeStart?.();
      return false;
    },
    [startAt, endAt, setIsResizingEventAtom, onIsResizingChange, onResizeStart]
  );

  const onDragMove = useCallback(
    (mouseEvent: MouseEvent) => {
      if (!willResize) return;
      mouseEvent.preventDefault();
      const deltaY = mouseEvent.clientY - initialY.current;
      const deltaMinutes = deltaYtoMinutes({
        deltaY,
        height: gridHeight.current,
      });
      const newPosition = roundDateTimeToNearestQuarterHour(
        initialTime.current.plus({ minutes: deltaMinutes })
      );

      if (
        sideRef.current === 'start' &&
        newPosition.toMillis() > lockedSideTime.current.toMillis()
      ) {
        sideRef.current = 'end';
      } else if (
        sideRef.current === 'end' &&
        newPosition.toMillis() < lockedSideTime.current.toMillis()
      ) {
        sideRef.current = 'start';
      }

      if (newPosition.ordinal !== startAt.ordinal) return;
      if (sideRef.current === 'start' && newPosition.minute === startAt.minute)
        return;
      if (sideRef.current === 'end' && newPosition.minute === endAt.minute)
        return;

      onResize({
        startAt: sideRef.current === 'start' ? newPosition : startAt,
        endAt: sideRef.current === 'end' ? newPosition : endAt,
      });
    },
    [endAt, onResize, startAt, willResize]
  );

  const onDragEnd = useCallback(
    (mouseEvent: MouseEvent) => {
      if (!willResize) return;
      mouseEvent.preventDefault();
      mouseEvent.stopPropagation();
      setWillResize(false);
      setIsResizingEventAtom(false);
      onIsResizingChange?.(false);
      onResizeEnd?.();
    },
    [willResize, setIsResizingEventAtom, onIsResizingChange, onResizeEnd]
  );

  useEffect(() => {
    if (willResize) {
      document.addEventListener('mousemove', onDragMove);
      document.addEventListener('mouseup', onDragEnd);
    } else {
      document.removeEventListener('mousemove', onDragMove);
      document.removeEventListener('mouseup', onDragEnd);
    }

    return () => {
      document.removeEventListener('mousemove', onDragMove);
      document.removeEventListener('mouseup', onDragEnd);
    };
  }, [onDragEnd, onDragMove, willResize]);

  useHotkey('esc', { scope: 'all', enabled: willResize && !disabled }, () => {
    setWillResize(false);
    onIsResizingChange?.(false);
    onResizeCancel?.();
  });

  if (disabled) {
    return null;
  }

  return (
    <>
      <span
        role="presentation"
        onPointerDown={(mouseEvent) => mouseEvent.stopPropagation()}
        onMouseDown={(mouseEvent) => onDragStart(mouseEvent, 'start')}
        onClick={(mouseEvent) => mouseEvent.stopPropagation()}
        className="absolute -top-px z-20 h-2 cursor-ns-resize"
        style={{
          left: offset,
          width: `calc(100% - ${offset}px)`,
          height:
            willResize && sideRef.current === 'start' ? '100%' : undefined,
        }}
      />
      <span
        role="presentation"
        onPointerDown={(mouseEvent) => mouseEvent.stopPropagation()}
        onMouseDown={(mouseEvent) => onDragStart(mouseEvent, 'end')}
        onClick={(mouseEvent) => mouseEvent.stopPropagation()}
        className="absolute -bottom-px z-20 h-2 cursor-ns-resize"
        style={{
          left: offset,
          width: `calc(100% - ${offset}px)`,
          height: willResize && sideRef.current === 'end' ? '100%' : undefined,
        }}
        data-testid={TEST_ID_EVENT_RESIZE_BOTTOM}
      />
    </>
  );
}

function deltaYtoMinutes({
  deltaY,
  height,
}: {
  deltaY: number;
  height: number;
}) {
  if (!height) return 0;
  const minutes = Math.round((deltaY / height) * MINUTES_IN_A_DAY);
  return Math.floor(minutes / 15) * 15;
}

export const isResizingEventAtom = atom(false);
