import { Getter, Setter } from 'jotai';
import { pick } from 'lodash';
import { IGridEvent } from 'types/events';
import {
  eventIdsPoolAtom,
  gridEventsFamily,
  optimisticEventsFamily,
} from '../eventAtoms';
import { removeEventFromPool } from './eventAtomsHelpers';

export function optimisticDeleteRecurringInstances(
  get: Getter,
  set: Setter,
  eventToDelete: IGridEvent
): void {
  const eventIds = [...get(eventIdsPoolAtom)];
  eventIds.forEach((eventId) => {
    const event = get(gridEventsFamily(eventId));
    if (
      event?.recurringEventId === eventToDelete.recurringEventId &&
      event?.startAt &&
      event?.startAt.toMillis() >= eventToDelete.startAt.toMillis()
    ) {
      removeEventFromPool(set, eventId);
    }
  });
}

export function optimisticUpdateRecurringInstances(
  get: Getter,
  set: Setter,
  eventToUpdate: IGridEvent
): void {
  const recurringEventId = eventToUpdate.recurringEventId;
  if (!recurringEventId) {
    return;
  }
  const eventIds = [...get(eventIdsPoolAtom)];
  eventIds.forEach((eventId) => {
    const event = get(gridEventsFamily(eventId));
    if (
      event?.recurringEventId === eventToUpdate.recurringEventId &&
      event?.startAt &&
      event?.startAt.toMillis() >= eventToUpdate.startAt.toMillis()
    ) {
      const propertiesToUpdate = pick(eventToUpdate, [
        'title',
        'description',
        'color',
        // 'startAt',
        // 'endAt',
        'allDay',
        'location',
        'rsvpForCalendarId',
        'attendees',
        'colorFamily',
        'rsvp',
      ]);
      // TODO: use this while working on https://linear.app/amie/issue/AMIE-2941/web-bug-changing-a-recurring-events-takes-a-long-time
      // let newStartAt = event.startAt;
      // if (propertiesToUpdate.startAt) {
      //   newStartAt = event.startAt.set({
      //     hour: propertiesToUpdate.startAt.hour,
      //     minute: propertiesToUpdate.startAt.minute,
      //   });
      // }
      // let newEndAt = event.endAt;
      // if (propertiesToUpdate.endAt) {
      //   newEndAt = event.endAt.set({
      //     hour: propertiesToUpdate.endAt.hour,
      //     minute: propertiesToUpdate.endAt.minute,
      //   });
      // }

      set(optimisticEventsFamily(eventId), (prevEvent) => ({
        ...prevEvent,
        ...propertiesToUpdate,
        // startAt: newStartAt,
        // endAt: newEndAt,
      }));

      // const newEventId = generateInstanceId({
      //   recurringEventId,
      //   startAt: newStartAt,
      // });

      // changeEventId({get, set, oldEventId: eventId, newEventId});
    }
  });
}

// function generateInstanceId({
//   recurringEventId,
//   startAt,
// }: {
//   recurringEventId: string;
//   startAt: DateTime;
// }): string {
//   return `${recurringEventId}_${startAt
//     .toUTC()
//     .toFormat("yyyyMMdd'T'HHmmss'Z'")}`;
// }
