import useCalendarsEagerly, {
  useEagerCalendars,
} from 'hooks/useCalendarsEagerly';
import { atom, useAtom } from 'jotai';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import React, { PropsWithChildren, useCallback, useEffect } from 'react';

const isTrackingAvatarsProximityAtom = atom(false);
const avatarsPositionAtom = atom<{ [email: string]: number }>({});
const closestAvatarsAtom = atom<{ email: string; distance: number }[]>(
  (get) => {
    return Object.entries(get(avatarsPositionAtom))
      .map(([email, distance]) => ({ email, distance }))
      .sort((a, b) => a.distance - b.distance);
  }
);

const closerAvatarEmailAtom = atom<string>(
  (get) => get(closestAvatarsAtom)[0]?.email
);

export const useAvatarsProximityTracking = () => {
  const setTracking = useUpdateAtom(isTrackingAvatarsProximityAtom);

  const startTracking = useCallback(() => {
    setTracking(true);
  }, [setTracking]);

  const stopTracking = useCallback(() => {
    setTracking(false);
  }, [setTracking]);

  return {
    startTracking,
    stopTracking,
  };
};

interface Props {
  emails: string[];
  refs: React.MutableRefObject<(HTMLDivElement | null)[]>;
}

export default function EagerEventsLoader({
  children,
  refs,
  emails,
}: PropsWithChildren<Props>) {
  const isTrackingAvatars = useAtomValue(isTrackingAvatarsProximityAtom);
  const setNearbyAvatars = useUpdateAtom(avatarsPositionAtom);
  const closerAvatar = useAtomValue(closerAvatarEmailAtom);
  const { eagerlyLoadCalendar } = useCalendarsEagerly();
  const eagerCalendars = useEagerCalendars();

  const DISTANCE_THRESHOLD = 100;

  const isEagerlyLoaded = useCallback(
    (nextEmailToLoad: string) => {
      return eagerCalendars.some((email) => email === nextEmailToLoad);
    },
    [eagerCalendars]
  );

  useEffect(() => {
    if (closerAvatar) {
      if (isEagerlyLoaded(closerAvatar)) {
        return;
      }
      eagerlyLoadCalendar(closerAvatar);
    }
  }, [closerAvatar, eagerlyLoadCalendar, isEagerlyLoaded]);

  const tracker = useCallback(
    (e: MouseEvent) => {
      refs.current.forEach((ref, index) => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          const height = ref.offsetHeight ?? 0;
          const width = ref.offsetWidth ?? 0;
          const left = rect.left + width / 2;
          const top = rect.top + height / 2;
          const leftDistance = e.clientX - left;
          const topDistance = e.clientY - top;

          const distance = Math.sqrt(
            leftDistance * leftDistance + topDistance * topDistance
          );

          if (distance < DISTANCE_THRESHOLD) {
            setNearbyAvatars((curr) => {
              return { ...curr, [emails[index]]: distance };
            });
          }
        }
      });
    },
    [setNearbyAvatars, refs, emails]
  );

  useEffect(() => {
    if (isTrackingAvatars) {
      window.addEventListener('mousemove', tracker);
    } else {
      window.removeEventListener('mousemove', tracker);
    }

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('mousemove', tracker);
    };
  }, [isTrackingAvatars, setNearbyAvatars, tracker]);

  return (
    <>
      <>{children}</>
    </>
  );
}
