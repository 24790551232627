import React, { useRef } from 'react';
import { useUpdateModal } from 'hooks/useModal';
import { useAtomValue } from 'jotai/utils';
import { HookProps, ListGroup, NavItemType } from '../../types';
import { quickMenuNavItemsAtom } from '../useSetNavItems';
import { ModalType } from 'types/modal';

export default function useTextInputGroup({
  enabled,
  searchTerm,
}: HookProps): ListGroup {
  const navItems = useAtomValue(quickMenuNavItemsAtom);
  const lastNavItem = navItems[navItems.length - 1];
  const { closeModal } = useUpdateModal();
  const listGroupRef = useRef<ListGroup>({
    title: 'Freeform text',
    items: [],
  });

  if (!enabled || lastNavItem?.type !== NavItemType.textInput || !searchTerm) {
    listGroupRef.current.items.length = 0;
    return listGroupRef.current;
  }

  listGroupRef.current.items = [
    {
      id: `text-input`,
      title: searchTerm,
      keywords: [],
      type: 'action',
      actionNameOverride: lastNavItem.getActionNameOverride
        ? lastNavItem.getActionNameOverride(searchTerm)
        : undefined,
      actionOverride: () => {
        closeModal(ModalType.QuickMenu);
        lastNavItem.callback(searchTerm);
      },
      Icon: () => <></>,
    },
  ];

  return listGroupRef.current;
}
