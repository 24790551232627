import { Theme_Enum } from '@graphql-types@';
import IconSidebarOpen from 'components/Icons/IconSidebarOpen';
import IconSun from 'components/Icons/IconSun';
import { useThemeContext } from 'contexts/theme';
import { currentDateAtom } from 'hooks/useCalendar';
import useInterface from 'hooks/useInterface';
import { useUpdateModal } from 'hooks/useModal';
import { useUpdatePreference } from 'hooks/usePreferences';
import { useAtomValue } from 'jotai/utils';
import React, { useRef } from 'react';
import { PreferenceName } from 'types/preference';
import { HookProps, ListGroup, ListItemAction } from '../types';
import { filterBySearch } from './useListGroups';

export default function useAppearanceActions({
  enabled,
  searchTerm,
}: HookProps): ListGroup {
  const { isSidepanelExpanded, setIsSidepanelExpanded } = useInterface();
  const { closeModal } = useUpdateModal();
  const themeContext = useThemeContext();
  const updateUserPreference = useUpdatePreference();
  const currentDate = useAtomValue(currentDateAtom);

  const listGroupRef = useRef<ListGroup>({
    title: 'Appearance',
    items: [],
  });
  listGroupRef.current.items.length = 0;

  // May the 4th be with you.
  const isStarWarsDay = currentDate.month === 5 && currentDate.day === 4;

  let toggleThemeTitle;

  if (themeContext.theme === Theme_Enum.Dark) {
    toggleThemeTitle = 'Change to light mode';
  } else if (isStarWarsDay) {
    toggleThemeTitle = 'Come to the dark side';
  } else {
    toggleThemeTitle = 'Change to dark mode';
  }

  const options: ListItemAction[] = [
    {
      id: 'toggle-theme',
      title: toggleThemeTitle,
      keywords: ['toggle', 'theme', 'light', 'dark'],
      type: 'action',
      actionOverride: () => {
        closeModal();
        updateUserPreference(
          PreferenceName.Theme,
          themeContext.theme === Theme_Enum.Dark
            ? Theme_Enum.Light
            : Theme_Enum.Dark
        );
      },
      Icon: () => <IconSun className="h-5 w-5 text-gray-400" />,
      actionNameOverride: 'Change',
    },
    {
      id: 'toggleSidebarVisibility',
      title: isSidepanelExpanded ? 'Close Todos panel' : 'Open Todos panel',
      keywords: ['sidebar', 'visibility', 'todos'],
      type: 'action',
      actionOverride: () => {
        closeModal();
        setIsSidepanelExpanded(!isSidepanelExpanded);
      },
      Icon: () => <IconSidebarOpen className="mr-0.5 h-5 w-5 text-gray-400" />,
    },
  ];

  if (!enabled) return listGroupRef.current;

  listGroupRef.current.items = filterBySearch(options, searchTerm);
  return listGroupRef.current;
}
