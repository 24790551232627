/* eslint-disable react-hooks/exhaustive-deps */
import { NewEventRsvpEnum, NewEventVisibilityEnum } from '@graphql-types@';
import { useUserEmail } from 'contexts/auth';
import {
  optimisticEventsFamily,
  eventIdsPoolAtom,
} from 'hooks/events/eventAtoms';
import useCalendar from 'hooks/useCalendar';
import { atom, useAtom } from 'jotai';
import { useAtomCallback } from 'jotai/utils';
import { flatten } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { IGridEvent } from 'types/events';
import { createArray } from 'utils/toolbox';

export const fakeEventModeAtom = atom(false);

export function useFakeEvents() {
  const [fakeMode, setFakeMode] = useAtom(fakeEventModeAtom);
  const { startDate, endDate } = useCalendar();
  const userEmail = useUserEmail();

  const toggleFakeEvents = useCallback(() => {
    if (!fakeMode) {
      addFakeEvents();
    } else {
      removeFakeEvents();
    }
    setFakeMode(!fakeMode);
  }, []);

  const addFakeEvents = useAtomCallback(
    useCallback(
      (_get, set) => {
        if (!userEmail) return;
        const fakeEvents = generateFakeEvents({
          startDate,
          endDate,
          calendarId: userEmail,
        });
        fakeEvents.forEach((event) => {
          set(optimisticEventsFamily(event.id), event);
        });
        set(eventIdsPoolAtom, (prevIds) => {
          return new Set([...prevIds, ...fakeEvents.map((event) => event.id)]);
        });
      },
      [startDate, endDate, userEmail]
    )
  );
  const removeFakeEvents = useAtomCallback(
    useCallback(
      (_get, set) => {
        if (!userEmail) return;
        set(eventIdsPoolAtom, (prevIds) => {
          return new Set([...prevIds].filter((id) => !id.startsWith('fake')));
        });
      },
      [startDate, endDate, userEmail]
    )
  );

  return { toggleFakeEvents, generateFakeEvents, removeFakeEvents };
}

function generateFakeEvents({
  startDate,
  endDate,
  calendarId,
}: {
  startDate: DateTime;
  endDate: DateTime;
  calendarId: string;
}): IGridEvent[] {
  const diffInDays = endDate.diff(startDate, 'days').days + 1;
  const res = createArray(diffInDays).map((day) => {
    return createArray(23).map((hour) => {
      return {
        id: 'fake-' + day + '-' + hour,
        title: 'fake-' + day + '-' + hour,
        calendarId,
        dayIndex: day,
        createdAt: DateTime.local().toJSDate(),
        startAt: startDate.plus({ days: day, hours: hour }),
        endAt: startDate.plus({ days: day, hours: hour + 1 }),
        visibility: NewEventVisibilityEnum.Public,
        isAllDay: false,
        videoConferences: [],
        description: 'This is a fake event',
        isOwnEvent: true,
        isSelfAsAttendee: true,
        allOtherGuestsDeclined: false,
        attendees: [],
        status: 'confirmed',
        location: '',
        colorFamily: 'gray',
        canEdit: true,
        belongsToUserCalendar: true,
        prevEndAt: DateTime.now(),
        prevStartAt: DateTime.now(),
        rsvp: NewEventRsvpEnum.Yes,
        isDraft: false,
      } as IGridEvent;
    });
  });
  return flatten(res);
}
