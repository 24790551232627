import React, { useMemo } from 'react';
import IconLocationMarker from 'components/Icons/IconLocationMarker';
import { useUpdateModal } from 'hooks/useModal';
import { useAtomValue } from 'jotai/utils';
import { parseCookies } from 'nookies';
import { useEffect, useRef } from 'react';
import { COOKIE_NAME_FIREBASE_ID_TOKEN } from 'utils/constants';
import { HookProps, ListGroup, NavItemType } from '../types';
import { flatMap } from 'lodash';
import { quickMenuNavItemsAtom } from './useSetNavItems';
import { useLocationAutoComplete } from 'components/EventPopover/EventLocation/useLocation';
import { useWorkerContext } from 'contexts/worker';

export default function useLocationInputGroup({
  enabled,
  searchTerm,
}: HookProps): ListGroup {
  const navItems = useAtomValue(quickMenuNavItemsAtom);
  const lastNavItem = navItems[navItems.length - 1];
  const { closeModal } = useUpdateModal();
  const listGroupRef = useRef<ListGroup>({
    title: 'Change location',
    items: [],
  });
  const { getComboBoxItems } = useLocationAutoComplete({
    value: searchTerm,
  });

  const { handleLocationSearch } = useWorkerContext();

  const Icon = useMemo(
    () => (
      <IconLocationMarker className="h-5 w-5 shrink-0 p-0.5 text-gray-400" />
    ),
    []
  );

  useEffect(() => {
    if (!searchTerm) return;

    // TODO: Maybe this should be done somewhere else?
    const cookies = parseCookies();
    const token = cookies[COOKIE_NAME_FIREBASE_ID_TOKEN];

    handleLocationSearch({
      text: searchTerm,
      token,

      // More would be great, but the Google Places Autocomplete API
      // returns a maximum of 5 results anyways
      // https://stackoverflow.com/questions/20785537/google-places-api-returning-only-5-results
      limit: 5,
    });
  }, [handleLocationSearch, searchTerm]);

  if (!enabled || lastNavItem?.type !== NavItemType.locationPicker) {
    listGroupRef.current.items.length = 0;
    return listGroupRef.current;
  }

  const comboBoxItems = getComboBoxItems();
  // Use lodash's flatMap instead of .filter.map chain in order to make TypeScript happy.
  listGroupRef.current.items = flatMap(comboBoxItems, (option, i) =>
    option.type === 'option'
      ? {
          id: `location-${i}`,
          title: option.text || option.value,
          keywords: (option.text || option.value).split(' '),
          type: 'action',
          actionOverride: () => {
            closeModal();
            lastNavItem.onChooseLocation(option.value || option.text);
          },
          Icon: () => (option.icon ? option.icon : Icon),
        }
      : []
  );

  return listGroupRef.current;
}
