/* eslint-disable react/display-name */
import { useFullEventsAtom } from 'hooks/useEventsSelection';
import { useRef } from 'react';
import { HookProps, ListGroup } from '../types';
import { filterBySearch } from './useListGroups';
import { useChangeEventColorAction } from './EventActions/useChangeEventColorAction';
import { useDoneEventActions } from './EventActions/useDoneEventActions';
import { useAllDayAction } from './EventActions/useAllDayAction';
import { useChangeTimeAction } from './EventActions/useChangeTimeAction';
import { useInviteGuestAction } from './EventActions/useInviteGuestAction';
import { useChangeLocationAction } from './EventActions/useChangeLocationAction';
import { useChangeRecurrenceAction } from './EventActions/useChangeRecurrenceAction';
import { useChangePrivacyAction } from './EventActions/useChangePrivacyAction';
import { useDeleteEventAction } from './EventActions/useDeleteEventAction';

export default function useEventsSelectionGroup({
  enabled,
  eventIds,
  searchTerm,
}: HookProps): ListGroup {
  const listGroupRef = useRef<ListGroup>({
    title: 'Options',
    items: [],
  });
  listGroupRef.current.items.length = 0;

  const fullEvents = useFullEventsAtom(eventIds);
  const changeEventColorListItem = useChangeEventColorAction(fullEvents);
  const doneListItems = useDoneEventActions(fullEvents);
  const allDayListItem = useAllDayAction(fullEvents);
  const changeTimeAction = useChangeTimeAction(fullEvents);
  const inviteGuestAction = useInviteGuestAction(fullEvents);
  const changeLocationAction = useChangeLocationAction(fullEvents);
  const changeRecurrenceAction = useChangeRecurrenceAction(fullEvents);
  const changePrivacyAction = useChangePrivacyAction(fullEvents);
  const deleteEventAction = useDeleteEventAction(fullEvents);

  if (!enabled || !fullEvents.length) return listGroupRef.current;

  if (fullEvents.length === 1) {
    listGroupRef.current.items.push(
      changeTimeAction,
      inviteGuestAction,
      changeLocationAction,
      changeRecurrenceAction
    );
  }

  listGroupRef.current.items.push(
    ...doneListItems,
    changeEventColorListItem,
    changePrivacyAction,
    deleteEventAction,
    allDayListItem
  );

  listGroupRef.current.items = filterBySearch(
    listGroupRef.current.items,
    searchTerm
  );
  return listGroupRef.current;
}
