import { ColorFamily } from '@graphql-types@';
import { getAvailableTimeToNextEvent } from 'components/Grid/utils';
import { userEmailAtom } from 'contexts/auth';
import { perfStart } from 'contexts/performance';
import { selectedSlotIdAtom } from 'hooks/calendarLink/creation/useSelectedSlotId';
import { visibleEventsAtom } from 'hooks/events/eventAtoms';
import { createDraftEventAtomCallback } from 'hooks/events/useUpdateGridEvent';
import { preferencesAtom } from 'hooks/preferences/preferencesAtoms';
import { calendarStartDateAtom, calendarEndDateAtom } from 'hooks/useCalendar';
import { allCalendarsAtom, ownCalendarsAtom } from 'hooks/useCalendars';
import { eventsSelectionAtom } from 'hooks/useEventsSelection';
import { favoritesAtom } from 'hooks/useFavorites';
import { modalAtom } from 'hooks/useModal';
import {
  calendarsWithDefaultAtom,
  getCalendarsAsAttendees,
} from 'hooks/useVisibleCalendars';
import { Getter, Setter } from 'jotai';
import { useAtomCallback } from 'jotai/utils';
import { contextMenuAtom } from 'joy/ContextMenu';
import { ModalType } from 'types/modal';
import {
  coordinatesToTargetDate,
  getTargetDimensions,
  MouseCoordinates,
} from 'utils/mouseEvents';

export function useClickToCreateEvent() {
  return useAtomCallback(handleClickToCreateAtomCallback);
}

function handleClickToCreateAtomCallback(
  get: Getter,
  set: Setter,
  {
    mouseEvent,
    initialCoordinates,
    openPopoverOnCreate,
    durationMinutes,
    isAllDay,
    id,
  }: {
    mouseEvent: React.MouseEvent<HTMLDivElement>;
    initialCoordinates: MouseCoordinates;
    openPopoverOnCreate: boolean;
    id: string;
    isAllDay?: boolean;
    durationMinutes?: number; // Used when dragging to create, we don't want the event duration to flicker
  }
) {
  if (get(contextMenuAtom)) {
    return;
  }
  // Clear eventsSelection and SlotSelection
  set(selectedSlotIdAtom, null);
  const gridDimensions = getTargetDimensions(mouseEvent);

  const targetDate = coordinatesToTargetDate({
    coordinates: initialCoordinates,
    width: gridDimensions.width,
    height: gridDimensions.height,
    startDay: get(calendarStartDateAtom),
    endDay: get(calendarEndDateAtom),
    roundToNearestQuarterHour: true,
  });
  if (!targetDate) {
    return;
  }
  const userPreferences = get(preferencesAtom);
  const events = get(visibleEventsAtom);
  const timeMinutesToNextEvent = isAllDay
    ? 60
    : getAvailableTimeToNextEvent({
        date: targetDate,
        events,
        maxDurationMinutes: userPreferences?.defaultDuration || 30,
      });
  const favorites = get(favoritesAtom);
  const calendars = get(allCalendarsAtom);
  const ownCalendars = get(ownCalendarsAtom);
  const visibleUnOwnedCalendars = get(calendarsWithDefaultAtom)
    .filter(
      (calendar) => !ownCalendars.some((ownCal) => ownCal.id === calendar)
    )
    .map((email) => ({
      email,
      colorFamily:
        calendars.find((cal) => cal.id === email)?.colorFamily ||
        ColorFamily.Gray,
      displayName:
        favorites.find((favorite) => favorite.emailAddress === email)
          ?.displayName || email,
    }));
  const userEmail = get(userEmailAtom);
  // Click to create draft event
  createDraftEventAtomCallback(get, set, {
    id,
    dayIndex: Math.floor(
      targetDate.diff(get(calendarStartDateAtom)).as('days')
    ),
    startAt: targetDate,
    endAt: targetDate.plus({
      minutes: durationMinutes ?? timeMinutesToNextEvent,
    }),
    isAllDay: !!isAllDay,
    attendees: getCalendarsAsAttendees(visibleUnOwnedCalendars).filter(
      (x) => x.email !== userEmail
    ),
  });
  if (openPopoverOnCreate) {
    perfStart('open-event-popover');
    set(modalAtom, ModalType.Event);
    set(eventsSelectionAtom, [id]);
  }
}
